import React, {createContext, useState} from 'react';
import {ITask} from "../ModelContracts";
import {useParams} from "react-router-dom";
import useApi from "../hooks/useApi/useApi";
import {useQuery} from "react-query";
import usePermissions from "../hooks/usePermissions/usePermissions";
import useAppUser from "../hooks/useAppUser/useAppUser";

interface ITaskContext {
	id: string,
	task?: ITask,
	isAdmin: boolean
}

const emptyContext : ITaskContext = {
	id: '',
	isAdmin: false
};

const TaskContext = createContext(emptyContext);

function TaskProvider(props: any) {
	const {taskId} = useParams();
	const user = useAppUser();
	const {isTaskAdmin} = usePermissions(user);
	const [state, setState] = useState<ITaskContext>(emptyContext);
	const {getTask} = useApi();
	useQuery<ITask>('task', () => getTask(taskId ?? ''), {
		enabled: Boolean(taskId),
		onSuccess: (task: ITask) => {
			setState({
				id: taskId ?? '',
				task,
				isAdmin: isTaskAdmin(task)
			})
		}
	});

	return (
		<TaskContext.Provider value={state}>
			{props.children}
		</TaskContext.Provider>
	);
}

export { TaskProvider, TaskContext };
