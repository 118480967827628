import React, {useState} from 'react';
import Channel from "../Channel/Channel";
import {IEvent, IPagedEventList} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {InfiniteData, useInfiniteQuery} from "react-query";

function AccountChannel() {
	const [events, setEvents] = useState<Array<IEvent>>([]);
	const {getEvents} = useApi();
	const pageSize = 10;
	const query = useInfiniteQuery<IPagedEventList>(
		'account-channel',
		({pageParam = 0}) => getEvents(pageSize, pageSize * pageParam),
		{
			onSuccess: (data: InfiniteData<IPagedEventList>) => {
				setEvents(data.pages.flatMap(page => page.items));
			},
			getNextPageParam: (lastPage, allPages) => {
				const loadedItems = allPages.flatMap(page => page.items);
				if (loadedItems.length >= lastPage.total) return;
				return allPages.length;
			}
		}
	);

	const handleLoadMore = async () => {
		await query.fetchNextPage();
	};

	return (
		<div className="account-channel" aria-label="Account Channel">
			<Channel
				events={events}
				hasMore={query.hasNextPage ?? false}
				emptyMessage="No activity yet."
				isLoading={query.isFetching}
				onLoadMore={handleLoadMore}/>
		</div>
	);
}

export default AccountChannel;
