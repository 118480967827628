import React from 'react';
import {IUser} from "../../ModelContracts";
import Gravatar from "react-gravatar";

import './UserList.scss';

interface IProps {
	users: Array<IUser>,
	avatarSize?: number,
	maxCount?: number
}

function UserList({ users, avatarSize, maxCount } : IProps) {
	const displayUsers = users.slice(0, maxCount);
	const extraUsers = users.filter(u => !displayUsers.includes(u));
	const extrasTooltip = extraUsers.map(eu => `${eu.firstName} ${eu.lastName}`).join('\n');

	return (
		<div className="user-list">
			{
				displayUsers.map(user => (
					<Gravatar
						key={user.id}
						email={user.email}
						title={`${user.firstName} ${user.lastName}`}
						protocol="https://"
						role="image"
						size={avatarSize ?? 24}
					/>
				))
			}
			{
				Boolean(extraUsers.length) &&
				<div className="extra-users">
					<span title={extrasTooltip}>+{extraUsers.length}</span>
				</div>
			}
		</div>
	);
}

export default UserList;
