import React from 'react';
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {Badge} from "react-bootstrap";
import {Link} from "react-router-dom";

function PendingInvitations() {
	const {getBoardInvitations} = useApi();
	const {data, isLoading} = useQuery(['board-invitations'], () => getBoardInvitations());

	if(isLoading || !data || !data.length) return <></>;
	return (
		<Link to="invitations">
			<Badge pill bg="secondary">
				{data.length} Pending Invitation{data.length > 1 ? 's' : ''}
			</Badge>
		</Link>
	);
}

export default PendingInvitations;
