import React, {forwardRef, useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

import './DatePicker.scss';

dayjs.extend(utc);

interface IProps {
	value: Date | null,
	onChange: {
		(date: Date | null) : void
	},
	label: string,
	excludeHistoric?: boolean
}

function DatePicker({ value, onChange, label, excludeHistoric } : IProps) {
	const [date, setDate] = useState<Date | null>(value);
	const dateInputRef = useRef(null);

	useEffect(() => {
		setDate(value);
	}, [value]);

	const DateInput = forwardRef((props: any, ref) => (
		<Form.Control {...props} ref={ref} />
	));

	const isValid = (date: Date) => {
		if(!excludeHistoric) return true;
		const currentDate =  dayjs().utc(false).startOf('day');
		return dayjs(date).utc(false).startOf('day') >= currentDate;
	};

	const handleChange = (updatedDate: Date | null) => {
		setDate(updatedDate);
		onChange(updatedDate);
	};

	return (
		<div aria-label="Select Date">
			<ReactDatePicker
				selected={date}
				onChange={handleChange}
				dateFormat="dd/MM/yyyy"
				isClearable
				placeholderText={label}
				filterDate={isValid}
				showPopperArrow={false}
				customInput={<DateInput ref={dateInputRef} type="text" aria-label={label} />}
			/>
		</div>
	);
}

export default DatePicker;
