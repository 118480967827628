import React from 'react';
import {IBoard, IInvitation} from "../../ModelContracts";
import {Alert, Form, ListGroup} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";

import './InviteMembers.scss';

interface IProps {
	board: IBoard
}

function InviteMembers({board}: IProps) {
	const queryClient = useQueryClient();
	const {inviteBoardMember} = useApi();
	const inviteBoardMemberCommand =
		useMutation((email: string) => inviteBoardMember(board.id, email), {
			onSuccess: async () => {
				await queryClient.invalidateQueries('board');
			}
		});

	const handleEnter = (e: React.KeyboardEvent) => {
		if (e.key !== "Enter") return;
		e.preventDefault();
		const input = e.currentTarget as HTMLInputElement;
		const value = input.value;
		input.value = '';
		inviteBoardMemberCommand.mutate(value);
	};

	if(!board || !board.invitations) return <></>;
	return (
		<div className="invite-members" aria-label="Invitations">
			<h3 className="h5 mt-5">Pending Invitations</h3>
			<Form.Group className="my-3">
				<Form.Control
					type="email"
					placeholder="Invite member"
					aria-label="Invite member"
					onKeyDown={handleEnter}  />
			</Form.Group>
			{
				Boolean(board.invitations.length) &&
				<ListGroup>
					{
						board.invitations.map((invitation: IInvitation) => (
							<ListGroup.Item key={invitation.id} className="invitation">
								<a href={`mailto:${invitation.email}`}
								   title={invitation.email}
								   className="text-body text-decoration-none">
									{invitation.email}
								</a>
							</ListGroup.Item>
						))
					}
				</ListGroup>
			}
			{
				!board.invitations.length &&
				<Alert variant="secondary">No invitations are pending</Alert>
			}
		</div>
	);
}

export default InviteMembers;
