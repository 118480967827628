import React, {useContext} from 'react';
import {TaskContext, TaskProvider} from "../../contexts/TaskContext";
import FilePreview from "../../components/FilePreview/FilePreview";
import {useNavigate, useParams} from "react-router-dom";
import {IFile} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";

function TaskFile() {
	const {id, task} = useContext(TaskContext);
	const {fileId} = useParams();
	const navigate = useNavigate();
	const file = task?.files.find(f => f.id === fileId);
	const {removeTaskFile} = useApi();
	const queryClient = useQueryClient();
	const removeTaskFileCommand = useMutation(() => removeTaskFile(id, fileId!), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
		}
	});

	const handleChange = (file: IFile) => {
		navigate(`/tasks/${id}/files/${file.id}`);
	};

	const handleClose = () => {
		navigate(`/boards/${task?.boardId}`);
	};

	const handleDelete = () => {
		removeTaskFileCommand.mutate();
	};

	if (!task || !file) return <></>;
	return (
		<FilePreview
			file={file}
			files={task.files}
			onClose={handleClose}
			onChange={handleChange}
			onDelete={handleDelete}/>
	);
}

function TaskFileWithData() {
	return (
		<TaskProvider>
			<TaskFile/>
		</TaskProvider>
	);
}

export default TaskFileWithData;
