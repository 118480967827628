import React, {FormEvent, useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {without} from "lodash";
import {Form, Button} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import SelectLabels from "../SelectLabels/SelectLabels";
import TextEditor from "../TextEditor/TextEditor";
import {ICreateTaskModel, IUser} from "../../ModelContracts";
import FileUpload from "../FileUpload/FileUpload";
import PendingFileList from "../PendingFileList/PendingFileList";
import SelectUsers from "../SelectUsers/SelectUsers";
import DatePicker from "../DatePicker/DatePicker";

import '../../styles/SideBar.scss';
import dayjs from "dayjs";

interface IProps {
	onCreate?: Function,
	boardId: string
}

function CreateTask({onCreate, boardId}: IProps) {
	const [name, setName] = useState('');
	const [description, setDescription] = useState<string>('');
	const [hasTemplate, setHasTemplate] = useState(false);
	const [labels, setLabels] = useState([] as Array<string>);
	const [files, setFiles] = useState<Array<File>>([]);
	const [assignees, setAssignees] = useState<Array<IUser>>([]);
	const [dueDate, setDueDate] = useState<Date | null>(null);
	const {createTaskWithFiles} = useApi();
	const queryClient = useQueryClient();
	const mutation = useMutation((newTask: ICreateTaskModel) => createTaskWithFiles(boardId, newTask, files), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
			await queryClient.invalidateQueries('board-events');
			handleHide();
		}
	});

	const handleHide = () => {
		if (onCreate) onCreate();
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.currentTarget?.value);
	};

	const handleDescriptionChange = (value: string) => {
		setDescription(value);
	};

	const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHasTemplate(e.currentTarget?.checked);
	};

	const handleLabelsChange = (labels: Array<string>) => {
		setLabels(labels);
		if(!labels.length) setHasTemplate(false);
	};

	const handleFilesSelected = (selectedFiles: Array<File>) => {
		setFiles([...files, ...selectedFiles]);
	};

	const handleFileDeleted = (file: File) => {
		const updateFiles = without(files, file);
		setFiles(updateFiles);
	};

	const handleDueDateChange = (value: Date | null) => {
		setDueDate(value);
	};

	const handleUserAdded = (user: IUser) => {
		setAssignees([...assignees, user]);
	};

	const handleUserRemoved = (user: IUser) => {
		const updatedAssignees = assignees.filter(a => a.id !== user.id);
		setAssignees(updatedAssignees);
	};

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		mutation.mutate({
			name,
			description,
			hasTemplate,
			labels,
			assignees: assignees.map(a => a.id),
			dueDate: dueDate ? dayjs(dueDate).toISOString() : null
		});
	};

	const isValid = () => Boolean(name);
	const isTemplateValid = () => Boolean(labels.length);

	return (
		<Form onSubmit={handleSubmit} aria-label="Create task" className="pb-5">
			<Form.Group className="my-3">
				<Form.Control
					type="text"
					placeholder="Task name"
					aria-label="Task name"
					onChange={handleNameChange}
					autoFocus/>
			</Form.Group>
			<Form.Group aria-label="Task Description">
				<TextEditor
					id="task-description"
					placeholder="Task description"
					value={description}
					onChange={handleDescriptionChange}/>
			</Form.Group>
			<SelectUsers
				placeholder="Assignees"
				existingUsers={assignees}
				onUserAdded={handleUserAdded}
				onUserRemoved={handleUserRemoved}
			/>
			<Form.Group>
				<DatePicker
					value={dueDate}
					onChange={handleDueDateChange}
					label="Due Date"
					excludeHistoric={true}
				/>
			</Form.Group>
			<FileUpload text="Add media to your task" onSelected={handleFilesSelected} />
			<PendingFileList files={files} onDeleted={handleFileDeleted} />
			<SelectLabels onChange={handleLabelsChange}/>
			<Form.Group>
				<Form.Check
					type="checkbox"
					id="create-template"
					label="Add to templates?"
					aria-describedby="template-description"
					checked={hasTemplate}
					onChange={handleTemplateChange}
					disabled={!isTemplateValid()}/>
				<Form.Text id="template-description" muted>
					Labelling the task and creating a template will automatically include this task
					in other boards which use the same labels.
				</Form.Text>
			</Form.Group>
			<Button variant="primary" type="submit" className="btn-create-task btn-side-bar" disabled={!isValid()}>
				Create Task
			</Button>
		</Form>
	);
}

export default CreateTask;
