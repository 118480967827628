import React, {useEffect} from 'react';
import {Alert, Button} from "react-bootstrap";
import EventCard from "../EventCard/EventCard";
import {IEvent} from "../../ModelContracts";
import {useInView} from "react-intersection-observer";

interface IProps {
	events: Array<IEvent>,
	hasMore: boolean,
	emptyMessage: string,
	isLoading: boolean,
	onLoadMore: Function
}

function Channel({ events, hasMore, emptyMessage, isLoading, onLoadMore } : IProps) {
	const {ref: loadMoreRef, inView} = useInView();

	const handleLoadMoreClick = () => {
		onLoadMore();
	};

	const getButtonText = () => {
		if(isLoading) return 'Loading More';
		return 'Load More';
	};

	useEffect(() => {
		if(!hasMore || !inView) return;
		onLoadMore();
	}, [onLoadMore, inView, hasMore]);

	return (
		<div className="channel recent-events" aria-label="Channel">
			{
				!events.length &&
				<Alert variant="secondary">{emptyMessage}</Alert>
			}
			{
				events.map((e, i) => (
					<div key={`channel-event-${i}`} className="mb-2">
						<EventCard event={e} />
					</div>
				))
			}
			{
				hasMore &&
				<div className="d-grid mt-3">
					<Button
						ref={loadMoreRef}
						variant="secondary"
						disabled={isLoading}
						onClick={handleLoadMoreClick}
						aria-label="Load More">
						{getButtonText()}
					</Button>
				</div>
			}
		</div>
	);
}

export default Channel;
