import React from 'react';
import 'chart.js/auto';
import {Container, Card, Col, Row, Stack} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {ICount, IDuration} from "../../ModelContracts";
import {Doughnut, Pie, PolarArea} from "react-chartjs-2";
import CompletedBoardList from "../CompletedBoardList/CompletedBoardList";

import './AccountReport.scss';
import StaleBoardList from "../StaleBoardList/StaleBoardList";

function AccountReport() {
	const {getAccountReport} = useApi();
	const {data: values, isLoading} = useQuery('account-report', getAccountReport);

	if (isLoading) return <></>;
	const formatDuration = (duration: IDuration) => {
		return `${duration.days} days ${duration.hours} hours`;
	};

	const getChartData = (values: Array<ICount>, colours?: Array<string>) => ({
		labels: values.map(v => v.name),
		datasets: [{
			data: values.map(v => v.value),
			backgroundColor: colours,
		}]
	});

	const emptyChartPlugin = {
		id: 'empty-chart',
		afterDraw: (chart: any) => {
			if (chart.data.labels.length === 0) {
				const {ctx, chartArea} = chart;

				ctx.save();

				const centerX = chartArea.width / 2;
				const centerY = chartArea.height / 2;
				const radius = (chartArea.width / 2) * 0.9;

				ctx.beginPath();
				ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
				ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
				ctx.fill();
				ctx.lineWidth = 1;
				ctx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
				ctx.stroke();

				ctx.font = 'bold 14px sans-serif';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillText("NO DATA", (chartArea.left + chartArea.right) / 2, (chartArea.top + chartArea.bottom) / 2);

				ctx.restore();
			}
		}
	};

	const chartOptions = {
		plugins: {
			legend: {
				display: false
			}
		}
	};

	return (
		<Container fluid className="account-report px-0" aria-label="Account Report">
			<Row>
				<Col>
					<Card className="summary">
						<Card.Body>
							<Row>
								<Col xs={12} md={4} className="mb-5 mb-md-0">
									<Stack aria-label="Average Onboarding Time" className="align-items-center">
										<h4>Average Onboarding Time</h4>
										<p className="m-0">{formatDuration(values.averageOnboardingTime)}</p>
									</Stack>
								</Col>
								<Col xs={12} md={4} className="mb-5 mb-md-0">
									<Stack aria-label="Active Onboardings" className="align-items-center">
										<h4>Active Onboardings</h4>
										<p className="m-0">{values.activeOnboardings}</p>
									</Stack>
								</Col>
								<Col xs={12} md={4}>
									<Stack aria-label="Outstanding Tasks" className="align-items-center">
										<h4>Outstanding Tasks</h4>
										<p className="m-0">{values.outstandingTasks}</p>
									</Stack>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col xs={12} md={4} className="mb-4 mb-md-0">
					<Card>
						<Card.Body>
							<Stack className="chart-container flex-column-reverse">
								<h5 className="mt-2 text-center">Tasks by Status</h5>
								<Stack role="figure" aria-label="Onboarding Tasks by Status">
									<Pie
										data={getChartData(
											values.taskCountByStatus, ['#BD2B23', '#4181BA', '#B0BA2F'])}
										plugins={[emptyChartPlugin]}
										options={chartOptions}/>
								</Stack>
							</Stack>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={4} className="mb-4 mb-md-0">
					<Card>
						<Card.Body>
							<Stack className="chart-container flex-column-reverse">
								<h5 className="mt-2 text-center">Remaining Tasks by Customer</h5>
								<Stack role="figure" aria-label="Outstanding Tasks by Customer">
									<Doughnut
										data={getChartData(values.outstandingTaskCountByCustomer)}
										plugins={[emptyChartPlugin]}
										options={chartOptions}/>
								</Stack>
							</Stack>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={4}>
					<Card>
						<Card.Body>
							<Stack className="chart-container flex-column-reverse">
								<h5 className="mt-2 text-center">Recently Modified Tasks</h5>
								<Stack role="figure" aria-label="Recently Modified Tasks">
									<PolarArea
										data={getChartData(values.recentlyModifiedTaskCountByCustomer)}
										options={chartOptions}/>
								</Stack>
							</Stack>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col xs={12} md={6} className="mb-4 mb-md-0">
					<CompletedBoardList boards={values.recentlyCompletedBoards} />
				</Col>
				<Col xs={12} md={6}>
					<StaleBoardList boards={values.stalestBoards} />
				</Col>
			</Row>
		</Container>
	);
}

export default AccountReport;
