import React from 'react';
import {Button, Col, Container, Row, Stack} from 'react-bootstrap';
import SetPlan from "../../components/SetPlan/SetPlan";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";

function Plan() {
	const {getBillingUrl} = useApi();
	const { refetch: redirectToBillingUrl } = useQuery(
		['get-billing-url'],
		() => getBillingUrl(),
		{
			enabled: false,
			onSuccess: data => window.location.assign(data)
		});

	const handleBillingClick = () => redirectToBillingUrl();

	return (
		<Container fluid>
			<Row className="mt-4 mb-2">
				<Col>
					<Stack direction="horizontal" className="mb-2 justify-content-between align-items-center">
						<h2 className="m-0">Manage Plan</h2>
						<Button onClick={handleBillingClick}>Billing & Invoices</Button>
					</Stack>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col className="p-0">
					<SetPlan/>
				</Col>
			</Row>
		</Container>
	);
}

export default Plan;