import React from 'react';
import {Stack} from "react-bootstrap";
import useAppUser from "../../hooks/useAppUser/useAppUser";
import {useQuery} from "react-query";
import useApi from "../../hooks/useApi/useApi";
import Gravatar from "react-gravatar";

function UserInfo() {
	const { name, accountId, email } = useAppUser();
	const {getAccountById} = useApi();
	const { data, isLoading } =
		useQuery(['account', accountId], () => getAccountById(accountId));

	if(isLoading || !data) return <></>;
	return (
		<Stack aria-label="User Information" direction="vertical">
			<Stack direction="horizontal" className="justify-content-between align-items-center">
				<span className="text-truncate" style={{ maxWidth: '70%' }} title={name}>{name}</span>
				<Gravatar email={email} protocol="https://" size={24}/>
			</Stack>
			<span className="text-truncate" style={{ maxWidth: '100%' }} title={data.name}>{data.name}</span>
		</Stack>
	);
}

export default UserInfo;
