import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Stack} from 'react-bootstrap';
import Label from "../Label/Label";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";

import './SelectLabels.scss';

interface IProps {
	onChange: {
		(labels: Array<string>): void
	},
	existingLabels?: Array<string>
}

function SelectLabels({onChange, existingLabels}: IProps) {
	const [labels, setLabels] = useState<Array<string>>(existingLabels ?? []);
	const [suggestion, setSuggestion] = useState<string | null>(null);
	const [searchText, setSearchText] = useState<string>('');
	const textFieldRef = useRef<HTMLInputElement>(null);
	const {getLabels} = useApi();
	useQuery(
		searchText,
		() => {
			if (!searchText) return [];
			return getLabels(undefined, searchText, 1, labels)
		},
		{
			onSuccess: (suggestedLabels: Array<string>) => {
				let suggestion = suggestedLabels.length && searchText ? suggestedLabels[0] : null;
				setSuggestion(suggestion);
			}
		});

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const currentText = e.currentTarget.value.toLowerCase();
		e.currentTarget.value = currentText;
		if (!currentText) setSuggestion(null);
		setSearchText(currentText);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		handleTab(e);
		handleEnter(e);
	};

	const handleTab = (e: React.KeyboardEvent) => {
		if (e.key !== "Tab" || !suggestion) return;
		e.preventDefault();
		const input = e.currentTarget as HTMLInputElement;
		input.value = suggestion;
		setSuggestion(null);
	};

	const addLabel = () => {
		const input = textFieldRef.current as HTMLInputElement;
		const value = input.value;
		input.value = '';
		input.focus();
		if (labels.find(l => l === value)) return;
		const updatedLabels = [...labels, value];
		setLabels(updatedLabels);
		setSearchText('');
		setSuggestion(null);
		onChange(updatedLabels);
	};

	const handleEnter = (e: React.KeyboardEvent) => {
		if (e.key !== "Enter") return;
		e.preventDefault();
		addLabel();
	};

	const handleClick = () => {
		addLabel();
	};

	const handleRemoveLabel = (label: string) => {
		const updatedLabels = labels.filter(l => l !== label);
		setLabels(updatedLabels);
		onChange(updatedLabels);
	};

	useEffect(() => {
		if (!existingLabels) return;
		setLabels(existingLabels);
	}, [existingLabels]);

	return (
		<Form.Group className="my-3 select-labels">
			<Stack direction="horizontal">
				<div className="w-100 position-relative">
					<Form.Control
						type="text"
						placeholder="Labels"
						aria-label="Add labels"
						ref={textFieldRef}
						onChange={handleChange}
						onKeyDown={handleKeyDown}/>
					{
						Boolean(suggestion) &&
						<span className="suggestion" aria-label="Suggested Label">{suggestion}</span>
					}
					{
						Boolean(searchText) &&
						<span className="advice">
							{ suggestion ? "[Tab to Complete]" : "[Enter to Confirm]" }
						</span>
					}
				</div>
				<Button className="fs-0" variant="link" disabled={!Boolean(searchText)} onClick={handleClick}>
					<i className="bi bi-plus-lg fs-4"/>
					Add Label
				</Button>
			</Stack>
			<div className="mt-2">
				{labels.map(label => <Label key={label} value={label} onRemove={handleRemoveLabel}/>)}
			</div>
		</Form.Group>
	);
}

export default SelectLabels;
