import React, {useState} from "react";
import {Button, Card, Modal} from "react-bootstrap";
import {IUser} from "../../ModelContracts";

import './UserCard.scss';
import useAppUser from "../../hooks/useAppUser/useAppUser";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";

interface IProps {
	user: IUser
}

function UserCard({user}: IProps) {
	const fullName = `${user.firstName} ${user.lastName}`;
	const appUser = useAppUser();
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const {deleteUser} = useApi();
	const queryClient = useQueryClient();

	const deleteUserCommand = useMutation(() => deleteUser(user.id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('users');
			await queryClient.invalidateQueries('available-users');
		}
	});

	const handleStartDeleting = () => setIsDeleting(true);
	const handleStopDeleting = () => setIsDeleting(false);
	const handleDelete = () => deleteUserCommand.mutate();

	return (
		<>
			<Card role="listitem" className="user-card bank-card card" aria-label={fullName}>
				<Card.Header>
					<h5>
						{fullName}
					</h5>
					{
						user.id !== appUser.id &&
                        <Button variant="link" title="Delete" onClick={handleStartDeleting}>
                            <i className="bi bi-trash"></i>
                            Delete
                        </Button>
					}
				</Card.Header>
				<Card.Body>
					<p className="text-truncate" title={user.email}>
						{user.email}
					</p>
				</Card.Body>
			</Card>
			<Modal show={isDeleting} role="dialog" aria-label="Delete User" onHide={handleStopDeleting}>
				<Modal.Header closeButton>
					<Modal.Title role="heading">Delete User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Are you sure you want to delete {user.firstName} {user.lastName}? They will be removed from
						boards, tasks and will no longer have access to Boarded.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={handleStopDeleting}>Cancel</Button>
					<Button variant="primary" onClick={handleDelete}>Delete</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default UserCard;
