import React, {useState} from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	ListGroup,
	Modal,
	Row,
	Toast,
	ToastContainer
} from "react-bootstrap";
import {isEqual} from 'lodash';
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQuery} from "react-query";
import {useDebounce} from 'use-debounce';
import {ISubscription} from "../../ModelContracts";
import './SetPlan.scss';
import {useSearchParams} from "react-router-dom";

function SetPlan() {
	const {getCurrentPlan, getProposedPlanSubscription, setPlan} = useApi();
	const [searchParams] = useSearchParams();
	const [isLoaded, setIsLoaded] = useState(false);
	const [isSwitching, setIsSwitching] = useState(false);
	const [onboardings, setOnboardings] = useState(0);
	const [isUpdated, setIsUpdated] =
		useState(searchParams.get('success') === 'true');
	const [isUnsuccessful, setIsUnsuccessful] =
		useState(searchParams.get('success') === 'false');
	const [users, setUsers] = useState(0);
	const [proposedPlanSubscription, setProposedPlanSubscription] =
		useState<ISubscription>({
			cost: 0,
			items: []
		});
	const [debouncedOnboardings] = useDebounce(onboardings, 500);
	const [debouncedUsers] = useDebounce(users, 500);
	const {data: currentPlan, isLoading} =
		useQuery(['get-current-plan'], () => getCurrentPlan(), {
			enabled: !isLoaded,
			onSuccess: (data) => {
				if (!onboardings) setOnboardings(data.availableOnboardings);
				if (!users) setUsers(data.availableUsers);
				setProposedPlanSubscription(data.subscription);
				setIsLoaded(true);
			}
		});
	useQuery(
		[debouncedOnboardings, debouncedUsers],
		() => getProposedPlanSubscription({onboardings, users}),
		{
			enabled: isLoaded,
			onSuccess: (data) => {
				setProposedPlanSubscription(data);
			}
		});
	const setPlanCommand =
		useMutation(async () => await setPlan({onboardings, users}), {
			onSettled: async (data) => {
				if (data?.redirectUri) {
					window.location.assign(data.redirectUri);
					return;
				}
				setIsLoaded(false);
				setIsUpdated(true);
			}
		});

	const currencyFormatter = Intl.NumberFormat('en-GB', {
		currency: 'GBP',
		style: 'currency'
	});

	const handleOnboardingsChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updated = Number(e.currentTarget.value);
		setOnboardings(updated);
	};

	const handleUsersChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updated = Number(e.currentTarget.value);
		setUsers(updated);
	};

	const handleKeepPlan = () => {
		setOnboardings(currentPlan?.availableOnboardings ?? 0);
		setUsers(currentPlan?.availableUsers ?? 0);
	};

	const handleStartSwitching = () => {
		setIsSwitching(true);
	}

	const handleStopSwitching = () => {
		setIsSwitching(false);
	}

	const handleSwitchPlan = () => {
		setPlanCommand.mutate();
		setIsSwitching(false);
	};

	const canSwitch = () => {
		return proposedPlanSubscription && !isEqual(
			currentPlan?.subscription,
			proposedPlanSubscription);
	};

	if (isLoading) return <></>;
	return (
		<>
			<Container aria-label="Set Plan" className="set-plan">
				<Row>
					<Col xs={12} md={6}>
						<Form.Label className="w-100" aria-label="Onboardings">
							Onboardings ({onboardings})
							<Form.Range className="mt-2"
							            min={Math.max(currentPlan?.activeOnboardings ?? 0, 2)}
							            max={200}
							            value={onboardings}
							            onChange={handleOnboardingsChange}/>
						</Form.Label>
					</Col>
					<Col xs={12} md={6}>
						<Form.Label className="w-100" aria-label="Users">
							Users ({users})
							<Form.Range className="mt-2"
							            min={Math.max(currentPlan?.activeUsers ?? 0, 1)}
							            max={200}
							            value={users}
							            onChange={handleUsersChange}/>
						</Form.Label>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col xs={12} md={6} className="plan-cost mb-4 mb-md-0" aria-label="Current Plan">
						<Card>
							<Card.Body>
								<Card.Title className="m-0 d-flex justify-content-between">
									<span>Current Plan</span>
									<span>
									{currencyFormatter.format(currentPlan?.subscription.cost ?? 0)} p/m
								</span>
								</Card.Title>
							</Card.Body>
							<ListGroup className="list-group-flush">
								{
									currentPlan?.subscription.items.map(item => (
										<ListGroup.Item key={item}>
											{item}
										</ListGroup.Item>
									))
								}
							</ListGroup>
							<Card.Body className="d-flex justify-content-center">
								<Button disabled={!canSwitch()} onClick={handleKeepPlan}>Keep Plan</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} md={6} className="plan-cost" aria-label="Proposed Plan">
						<Card>
							<Card.Body>
								<Card.Title className="m-0 d-flex justify-content-between">
									<span>Proposed Plan</span>
									{
										<span>
											{currencyFormatter.format(proposedPlanSubscription.cost ?? 0)} p/m
										</span>
									}
								</Card.Title>
							</Card.Body>
							<ListGroup className="list-group-flush">
								{
									proposedPlanSubscription.items.map(item => (
										<ListGroup.Item key={item}>
											{item}
										</ListGroup.Item>
									))
								}
							</ListGroup>
							<Card.Body className="d-flex justify-content-center">
								<Button disabled={!canSwitch()} onClick={handleStartSwitching}>Switch Plan</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<Modal show={isSwitching} role="dialog" aria-label="Switch Plan" onHide={handleStopSwitching}>
				<Modal.Header closeButton>
					<Modal.Title role="heading">Switch Plan</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you wish to switch plans? Your usage for
						this month will pro-rated and added to your next bill.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={handleStopSwitching}>Cancel</Button>
					<Button variant="primary" onClick={handleSwitchPlan}>Switch</Button>
				</Modal.Footer>
			</Modal>
			<ToastContainer position="bottom-start" style={{zIndex: 1100}}>
				<Toast show={isUpdated}
				       onClose={() => setIsUpdated(false)}
				       autohide
				       delay={5000}
				       bg="success">
					<Toast.Header className="d-flex justify-content-between align-items-center">
						<h4 className="fs-6">Plan Updated</h4>
					</Toast.Header>
					<Toast.Body className="text-white text-center">
						Your plan was successfully updated.
					</Toast.Body>
				</Toast>
			</ToastContainer>
			<ToastContainer position="bottom-start" style={{zIndex: 1100}}>
				<Toast show={isUnsuccessful}
				       onClose={() => setIsUnsuccessful(false)}
				       autohide
				       delay={5000}
				       bg="danger">
					<Toast.Header className="d-flex justify-content-between align-items-center">
						<h4 className="fs-6">Not Updated</h4>
					</Toast.Header>
					<Toast.Body className="text-white text-center">
						Your plan was not updated.
					</Toast.Body>
				</Toast>
			</ToastContainer>
		</>
	);
}

export default SetPlan;