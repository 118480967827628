import React from 'react';
import {ITask, ITaskList} from "../../ModelContracts";
import {Draggable, Droppable} from "react-beautiful-dnd";
import TaskCard from "../TaskCard/TaskCard";

import './TaskList.scss';
import {Button, Stack} from "react-bootstrap";

interface IProps {
	taskList: ITaskList,
	emptyText?: string,
	onAdded?: Function | undefined,
	isAdmin: boolean
}

function TaskList({taskList, emptyText, onAdded, isAdmin}: IProps) {
	const handleAddClicked = () => {
		if (!onAdded) return;
		onAdded();
	}

	return (
		<div
			role="list"
			aria-label={taskList.name}
			className="task-list p-3">
			<Stack direction="horizontal" className="justify-content-between align-items-center">
				<h4 className="h5">{taskList.name}</h4>
				{
					Boolean(onAdded) &&
					isAdmin &&
            <Button variant="link" title="Add Task" onClick={handleAddClicked} className="fs-0 p-0">
                Add Task
                <i className="bi bi-plus-lg fs-4 link-secondary"></i>
            </Button>
				}
			</Stack>
			<Droppable droppableId={taskList.id}>
				{
					(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className="items"
							{...provided.droppableProps}>
							{
								taskList.tasks.map((t: ITask, i: number) => (
									<Draggable key={t.id} draggableId={t.id} index={i}>
										{
											(providedDraggable, snapshotDraggable) => (
												<div
													ref={providedDraggable.innerRef}
													{...providedDraggable.draggableProps}
													{...providedDraggable.dragHandleProps}>
													<TaskCard key={t.id} task={t}/>
												</div>
											)
										}
									</Draggable>
								))
							}
							{
								taskList.tasks.length <= 0 && <p>{emptyText || "Nothing to show here."}</p>
							}
							{provided.placeholder}
						</div>
					)
				}
			</Droppable>
		</div>

	);
}

export default TaskList;
