import React from 'react';
import {useIsFetching, useQueryClient} from "react-query";
import {Stack} from "react-bootstrap";

import './Loader.scss';

function Loader() {
	const isFetching = useIsFetching();
	const queryClient = useQueryClient();

	const queryKeys = queryClient.getQueryCache().getAll().map(query => query.queryKey);

	const fetchingQueries = queryKeys.filter(key => {
		const state = queryClient.getQueryState(key);
		return state?.isFetching;
	});

	if(!isFetching || !fetchingQueries.length) return <></>;

	return (
		<Stack className="loader align-items-center justify-content-center">
			<img src="/loader.svg"
			     alt="Loading"
			     aria-label="Loader" />
		</Stack>
	);
}

export default Loader;