import React from 'react';
import {Card, Button} from "react-bootstrap";
import {IAccount} from "../../ModelContracts";
import useAppUser from "../../hooks/useAppUser/useAppUser";
import useApi from "../../hooks/useApi/useApi";
import {useMutation} from "react-query";

interface IProps {
	account: IAccount
}

function AccountCard({ account } : IProps) {
	const appUser = useAppUser();
	const isImpersonatingAccount = account.id === appUser.impersonateAccountId;
	const {impersonate, unimpersonate} = useApi();

	const impersonateMutation = useMutation(() => impersonate(account.id), {
		onSuccess: async () => {
			window.location.reload();
		}
	});

	const unimpersonateMutation = useMutation(() => unimpersonate(), {
		onSuccess: async () => {
			window.location.reload();
		}
	})

	const handleImpersonate = async () => impersonateMutation.mutate();
	const handleUnimpersonate = async () => unimpersonateMutation.mutate();

	return (
		<Card className="account-card bank-card m-2">
			<Card.Body>
				<Card.Title><h5>{account.name}</h5></Card.Title>
				{
					!isImpersonatingAccount &&
					<Button variant="secondary" className="mt-2" onClick={handleImpersonate}>Impersonate</Button>
				}
				{
					isImpersonatingAccount &&
					<Button variant="secondary" className="mt-2" onClick={handleUnimpersonate}>Unimpersonate</Button>
				}
			</Card.Body>
		</Card>
	);
}

export default AccountCard;
