import React from 'react'
import {ITask} from "../../ModelContracts";

import './BoardProgressChart.scss';

interface IProps {
	tasks: Array<ITask>
}

function BoardProgressChart({tasks} : IProps) {
	const getCompletion = () => {
		if(!tasks.length) return '0%';
		const percentage = (tasks.filter(t => t.isComplete).length / tasks.length) * 100;
		return `${Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 }).format(percentage)}%`;
	};

	return (
		<div className="board-progress-chart">
			<div
				aria-label="Board Progress"
				role="progressbar"
				style={{ width: getCompletion() }}
				title={`${getCompletion()} Complete`}>
			</div>
		</div>
	);
}

export default BoardProgressChart;
