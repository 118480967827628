import React, {useState} from 'react';
import useApi from "../../hooks/useApi/useApi";
import {InfiniteData, useInfiniteQuery} from "react-query";
import {IEvent, IPagedEventList} from "../../ModelContracts";
import Channel from "../Channel/Channel";

interface IProps {
	boardId: string
}

function BoardChannel({ boardId } : IProps) {
	const [events, setEvents] = useState<Array<IEvent>>([]);
	const {getBoardEvents} = useApi();
	const pageSize = 10;
	const query = useInfiniteQuery<IPagedEventList>(
		'board-channel',
		({ pageParam = 0 }) => getBoardEvents(boardId, pageSize, pageSize * pageParam),
		{
			onSuccess: (data: InfiniteData<IPagedEventList>) => {
				setEvents(data.pages.flatMap(page => page.items));
			},
			getNextPageParam: (lastPage, allPages) => {
				const loadedItems = allPages.flatMap(page => page.items);
				if(loadedItems.length >= lastPage.total) return;
				return allPages.length;
			}
		}
	);

	const handleLoadMore = async () => {
		await query.fetchNextPage();
	};

	return (
		<div className="board-channel" aria-label="Board Channel">
			<Channel
				events={events}
				hasMore={query.hasNextPage ?? false}
				emptyMessage="No activity on this board yet."
				isLoading={query.isFetching}
				onLoadMore={handleLoadMore} />
		</div>
	);
}

export default BoardChannel;
