import React, {useState} from 'react';
import {useQuery} from "react-query";
import {Form, Button, Navbar, Nav, OverlayTrigger, Tooltip, Stack, Container} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import useApi from '../../hooks/useApi/useApi';
import {IBoard} from '../../ModelContracts';

import './BoardsNav.scss';

interface IProps {
	onAddCustomer: Function
}

function BoardsNav({onAddCustomer}: IProps) {
	const { boardId } = useParams();
	const [boards, setBoards] = useState([] as IBoard[]);
	const [searchTerm, setSearchTerm] = useState('');

	const {getBoards, getAvailableOnboardings} = useApi();

	useQuery<IBoard[], Error>('boards', getBoards, {
		onSuccess: (boards: IBoard[]) => {
			setBoards(boards);
		}
	});

	const {data: availableOnboardings, isLoading} =
		useQuery(['available-onboardings'], getAvailableOnboardings);

	const getSearchedBoards = () => {
		const matchesSearch = (value: string) => value.toLowerCase().includes(searchTerm.toLowerCase());

		if (!searchTerm) return boards;
		return boards.filter((b: any) => matchesSearch(b.name));
	}

	const getBoardUrl = (board: IBoard) => {
		return `/boards/${board.id}`;
	}

	const handleOpen = () => {
		onAddCustomer();
	};

	return (
		<Navbar expand="lg" className="boards-nav" aria-label="Customers nav" fixed="top">
			<Container fluid>
				<Navbar.Text className="d-md-none">Customers</Navbar.Text>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" className="mb-1 mb-md-0" />
				<Navbar.Collapse id="responsive-navbar-nav" className="flex-column">
					<Form className="col-12">
						<Form.Control
							type="text"
							placeholder="Search"
							aria-label="Search"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value)}
						/>
					</Form>

					<Nav className="flex-column col-12">
						{
							getSearchedBoards().map((b: any) =>
								<Nav.Item key={b.id}>
									<Link className={`nav-link ${boardId === b.id ? 'selected' : ''}`} to={getBoardUrl(b)}>{b.name}</Link>
								</Nav.Item>
							)
						}
						{
							!isLoading &&
                            <Nav.Item className="create-customer">
                                <Stack className="align-items-center">
                                    <OverlayTrigger overlay={
										props => (
											<Tooltip {...props}>
												{
													`${availableOnboardings?.amountUsed ?? 0} of ` +
													`${availableOnboardings?.amountInPlan ?? 0} available active onboardings used`
												}
											</Tooltip>
										)
									}>
                                        <Button
                                            className="btn-create-customer"
                                            variant="link"
                                            onClick={handleOpen}
                                            disabled={!availableOnboardings?.moreAvailable}>
                                            <i className="bi bi-plus-circle-fill"></i>
                                            Add Customer
                                        </Button>
                                    </OverlayTrigger>
									{
										!availableOnboardings?.moreAvailable &&
                                        <p className="small fst-italic px-3">
                                            <Link to={"/plan"}
                                                  className="text-decoration-underline">Upgrade plan</Link> or archive
                                            customer
                                            to add more
                                        </p>
									}
                                </Stack>
                            </Nav.Item>
						}
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default BoardsNav;
