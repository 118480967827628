import React from 'react';
import dayjs from "dayjs";
import {Badge} from "react-bootstrap";
import {ITask} from "../../ModelContracts";
import useFormattedDate from "../../hooks/useFormattedDate/useFormattedDate";

interface IProps {
	task: ITask
}

function TaskStatus({ task } : IProps) {
	const [formattedDate] = useFormattedDate(task.dueDate);

	const getMode = () => {
		const currentDate = dayjs().startOf('day');
		const providedDate = dayjs(task.dueDate);
		if(providedDate < currentDate) return 'danger';
		if(providedDate.subtract(1, 'week') < currentDate) return 'warning';
		return 'secondary';
	};

	if(task.isComplete) return <Badge bg="success" role="status" aria-label="Done" pill className="status">Done</Badge>;
	if(!task.dueDate) return <></>;
	return (
		<Badge pill className="due-date status" bg={getMode()} aria-label="Due Date">
			<span role="status" aria-label={`Due: ${formattedDate}`}>{`Due: ${formattedDate}`}</span>
		</Badge>
	);
}

export default TaskStatus;
