import React, {createContext, useState} from 'react';
import {IBoard, ITaskList} from "../ModelContracts";
import useAppUser from "../hooks/useAppUser/useAppUser";
import usePermissions from "../hooks/usePermissions/usePermissions";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import useApi from "../hooks/useApi/useApi";

interface IBoardContext {
	board: IBoard,
	isAdmin: boolean
}

const emptyContext = {
	board: {
		taskLists: [] as Array<ITaskList>,
		labels: [] as Array<string>
	} as IBoard,
	isAdmin: false
}

const BoardContext = createContext(emptyContext);

function BoardProvider(props: any) {
	const {boardId} = useParams() as { boardId: string };
	const user = useAppUser();
	const {isBoardAdmin} = usePermissions(user);
	const [state, setState] = useState<IBoardContext>(emptyContext);
	const {getBoardById} = useApi();
	useQuery<IBoard>(['board', boardId], () => getBoardById(boardId), {
		enabled: Boolean(boardId),
		onSuccess: (board: IBoard) => {
			setState({
				board,
				isAdmin: isBoardAdmin(board)
			})
		}
	});

	return (
		<BoardContext.Provider value={state}>
			{props.children}
		</BoardContext.Provider>
	)
}

export { BoardContext, BoardProvider };
