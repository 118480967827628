import {useAuth0} from "@auth0/auth0-react";
import {IAppUser} from "../../ModelContracts";

function useAppUser() {
	const {user} = useAuth0();
	const isAdmin = Boolean(user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/boarded_admin`]);
	const defaultImpersonateAccountId = '00000000-0000-0000-0000-000000000000';
	const accountId = (user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/account_id`]) || '';
	const impersonateAccountId =
		(user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/impersonate_account_id`]) || defaultImpersonateAccountId;
	const isImpersonating = impersonateAccountId !== defaultImpersonateAccountId;
	const userId = (user && user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/user_id`]) || '';
	const isSetup = Boolean(userId);
	const appUser : IAppUser = {
		email: user?.email ?? '',
		name: user?.name ?? '',
		accountId,
		id: userId,
		isAdmin,
		impersonateAccountId,
		isImpersonating,
		isSetup
	};

	return appUser;
}

export default useAppUser;
