import React, {useState} from 'react';
import {Button, ButtonGroup, FormGroup} from "react-bootstrap";
import TextEditor from "../TextEditor/TextEditor";

interface IProps {
	onAdded: {
		(value: string) : void
	}
}

function AddComment({ onAdded } : IProps) {
	const removeHtml = (value: string) => value.replace(/<[^>]*>?/gm, '');
	const [comment, setComment] = useState<string>('');
	const [hasComment, setHasComment] = useState<boolean>(false);

	const handleChange = (value: string) => {
		setComment(value);
		const commentText = removeHtml(value);
		setHasComment(Boolean(commentText));
	};

	const handleClick = () => {
		onAdded(comment);
		setComment('');
		setHasComment(false);
	};

	return (
		<FormGroup className="mb-3" aria-label="Add Comment">
			<TextEditor
				id="add-comment"
				placeholder="Add comment"
				value={comment}
				onChange={handleChange}
			/>
			<ButtonGroup className="d-flex justify-content-end">
				<Button
					variant="secondary"
					size="sm"
					className="mt-2 flex-grow-0"
					disabled={!hasComment}
					onClick={handleClick}>
					Add Comment
				</Button>
			</ButtonGroup>
		</FormGroup>
	);
}

export default AddComment;
