import React, {useState} from 'react';
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {IAccount} from "../../ModelContracts";
import {Button, Col, Container, Row} from "react-bootstrap";
import AccountCard from "../../components/AccountCard/AccountCard";
import CreateAccount from "../../components/CreateAccount/CreateAccount";
import EmptyList from "../../components/EmptyList/EmptyList";

function Admin() {
	const [accounts, setAccounts] = useState<Array<IAccount>>([]);
	const [isCreating, setIsCreating] = useState(false);

	const handleStartCreating = () => setIsCreating(true);
	const handleStopCreating = () => setIsCreating(false);

	const {getAccounts} = useApi();

	useQuery('accounts', getAccounts, {
		onSuccess: (accounts: IAccount[]) => {
			setAccounts(accounts);
		}
	});

	return (
		<Container fluid className="mt-2">
			<Row>
				<Col>
					<h2>Admin</h2>
				</Col>
				<Col className="d-flex justify-content-end">
					<Button onClick={handleStartCreating}>Create Account</Button>
				</Col>
			</Row>
			<Row className="mt-4">
				{
					accounts.map((account : IAccount) => (
						<Col key={account.id} xs={6} md={3} className="mb-3">
							<AccountCard account={account} />
						</Col>
					))
				}
				{
					!accounts.length && <EmptyList />
				}
			</Row>
			<CreateAccount isVisible={isCreating} onClose={handleStopCreating} />
		</Container>
	);
}

export default Admin;
