import React, {FormEvent, useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {Form, Offcanvas, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import useApi from "../../hooks/useApi/useApi";
import SelectLabels from "../SelectLabels/SelectLabels";
import {ICreateCustomerModel, ICustomer} from "../../ModelContracts";

import '../../styles/SideBar.scss';

interface IProps {
	isVisible: boolean,
	onClose?: Function
}

function CreateCustomer({isVisible = false, onClose}: IProps) {
	const [name, setName] = useState("");
	const [labels, setLabels] = useState([] as Array<string>);
	const navigate = useNavigate();
	const {createCustomer} = useApi();
	const queryClient = useQueryClient();
	const mutation = useMutation((newCustomer : ICreateCustomerModel) => createCustomer(newCustomer), {
		onSuccess: async (created : ICustomer) => {
			await queryClient.invalidateQueries('boards');
			await queryClient.invalidateQueries('available-onboardings');
			handleHide();
			navigate(`/boards/${created.boards[0]}`)
		}
	});

	const handleHide = () => {
		if (onClose) onClose();
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.currentTarget?.value);
	};

	const handleLabelsChange = (labels: Array<string>) => {
		setLabels(labels);
	};

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		mutation.mutate({
			name,
			labels
		});
	};

	return (
		<Offcanvas
			show={isVisible} onHide={handleHide} placement="end" className="side-bar" aria-label="Create customer">
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Create Customer</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Form onSubmit={handleSubmit} aria-label="Create customer">
					<Form.Group>
						<Form.Control type="text" placeholder="Customer name"
									  aria-label="Customer name" onChange={handleNameChange}/>
					</Form.Group>
					<SelectLabels onChange={handleLabelsChange} />
					<Button variant="primary" type="submit" className="btn-side-bar">
						Create Customer
					</Button>
				</Form>
			</Offcanvas.Body>
		</Offcanvas>
	);
}

export default CreateCustomer;
