import React from 'react';
import {Col, Container, Image, ListGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import './Error.scss';

function Error() {
	return (
		<Container fluid className="error mt-4">
			<Row>
				<Col>
					<h2>Oops! We've Hit a Snag 🛠️</h2>
					<p>We're really sorry for the inconvenience. Our team has been notified, and we're working to fix this as
						quickly as possible.</p>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col xs={12} md={6} className="mb-4">
					<h4 className="mb-3">What can you do?</h4>
					<ListGroup>
						<ListGroup.Item>
							<strong>Try Again:</strong> Sometimes a simple refresh can work wonders.
						</ListGroup.Item>
						<ListGroup.Item>
							<strong>Go Home:</strong> Head back to the <Link to={"/"} title="Home">dashboard</Link> to make sure
							you haven't taken a wrong turn.
						</ListGroup.Item>
						<ListGroup.Item>
							<strong>Contact Support:</strong> If the issue persists,
							please <a href="mailto:support@boarded.app" title="Email support">let us know</a>. We're here to help!
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col xs={12} md={5} className="d-flex justify-content-center justify-content-md-end">
					<Image src="img-error.png"/>
				</Col>
			</Row>
		</Container>
	);
}

export default Error;