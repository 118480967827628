import React, {useState} from 'react';
import dayjs from "dayjs";
import {useMutation, useQueryClient} from "react-query";
import {Form, Col, Row} from "react-bootstrap";
import TextEditor from "../TextEditor/TextEditor";
import SelectLabels from "../SelectLabels/SelectLabels";
import SelectUsers from "../SelectUsers/SelectUsers";
import DatePicker from "../DatePicker/DatePicker";
import useApi from "../../hooks/useApi/useApi";
import {ITask, IUser} from "../../ModelContracts";

interface IProps {
	task: ITask
}

function EditTask({task}: IProps) {
	const [name, setName] = useState<string>(task.name);
	const [description, setDescription] = useState<string>(task.description);
	const [labels] = useState<Array<string>>(task.labels);
	const [assignees] = useState<Array<IUser>>(task.assignees);
	const date = task.dueDate ? dayjs(task.dueDate).toDate() : null;
	const [dueDate, setDueDate] = useState<Date | null>(date);
	const {
		assignTask,
		clearTaskDueDate,
		renameTask,
		setTaskDescription,
		setTaskDueDate,
		setTaskLabels,
		unassignTask
	} = useApi();
	const queryClient = useQueryClient();
	const updateOptions = {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
			await queryClient.invalidateQueries('task');
			await queryClient.invalidateQueries('board-events');
		}
	};
	const assignTaskCommand = useMutation((userId: string) => assignTask(task.id, userId), updateOptions);
	const clearTaskDueDateCommand = useMutation(() => clearTaskDueDate(task.id), updateOptions);
	const setTaskDescriptionCommand = useMutation(
		(description: string) => setTaskDescription(task.id, description), updateOptions);
	const renameTaskCommand = useMutation((name: string) => renameTask(task.id, name), updateOptions);
	const setTaskDueDateCommand = useMutation((date: Date) => setTaskDueDate(task.id, date), updateOptions);
	const setTaskLabelsCommand = useMutation(
		(labels: Array<string>) => setTaskLabels(task.id, labels), updateOptions);
	const unassignTaskCommand = useMutation((userId: string) => unassignTask(task.id, userId), updateOptions);

	const handleRename = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.value;
		setName(name);
	};

	const handleRenameComplete = () => {
		if(name === task.name) return;
		renameTaskCommand.mutate(name);
	}

	const handleDescriptionChange = (content: string) => {
		setDescription(content);
	};

	const handleDescriptionChangeComplete = () => setTaskDescriptionCommand.mutate(description);

	const handleDueDateChange = (value: Date | null) => {
		setDueDate(value);
		value ? setTaskDueDateCommand.mutate(value) : clearTaskDueDateCommand.mutate();
	};

	const handleLabelChange = (labels: Array<string>) => {
		setTaskLabelsCommand.mutate(labels);
	};

	const handleUserAdded = (user: IUser) => {
		assignTaskCommand.mutate(user.id);
	};

	const handleUserRemoved = (user: IUser) => {
		unassignTaskCommand.mutate(user.id);
	};

	return (
		<div className="edit-task" aria-label="Edit Task">
			<Row>
				<Col>
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							placeholder="Task name"
							aria-label="Task name"
							value={name}
							onChange={handleRename}
							onBlur={handleRenameComplete}
							autoFocus/>
					</Form.Group>
					<Form.Group>
						<TextEditor
							id="task-description"
							data-testid="Task description"
							placeholder="Task description"
							value={description}
							onChange={handleDescriptionChange}
							onBlur={handleDescriptionChangeComplete}
						/>
					</Form.Group>
					<SelectLabels existingLabels={labels} onChange={handleLabelChange}/>
					<SelectUsers
						placeholder="Assignees"
						existingUsers={assignees}
						onUserAdded={handleUserAdded}
						onUserRemoved={handleUserRemoved}
					/>
					<Form.Group>
						<DatePicker
							value={dueDate}
							onChange={handleDueDateChange}
							label="Due Date"
							excludeHistoric={true}
						/>
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
}

export default EditTask;
