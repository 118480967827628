import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {IBoardInvitation} from "../../ModelContracts";
import EmptyList from "../../components/EmptyList/EmptyList";
import InvitationCard from "../../components/InvitationCard/InvitationCard";

function Invitations() {
	const {getBoardInvitations} = useApi();
	const {data, isLoading} = useQuery(['board-invitations'], () => getBoardInvitations());

	if(isLoading) return <></>;
	return (
		<Container fluid>
			<Row className="mt-4 mb-2">
				<Col>
					<h2>Invitations</h2>
				</Col>
			</Row>
			<Row className="mt-4">
				{
					data.map((invitation : IBoardInvitation) => (
						<Col key={invitation.id} xs={6} md={3}>
							<InvitationCard invitation={invitation} />
						</Col>
					))
				}
				{
					!data.length && <EmptyList />
				}
			</Row>
		</Container>
	);
}

export default Invitations;
