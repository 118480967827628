import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

function useFormattedDate(date: string | Date | undefined | null, format?: string) {
	if(!date) return [''];
	const formatted = dayjs(date).format(format ?? 'Do MMM YY');
	return [formatted];
}

export default useFormattedDate;
