import React from 'react';
import {IEvent, IBoardEvent} from "../../ModelContracts";
import {Badge, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {Link} from "react-router-dom";
import './EventCard.scss';

dayjs.extend(advancedFormat);

interface IProps {
	event: IEvent,
	maxLength?: number
}

function EventCard({event, maxLength}: IProps) {
	function isBoardEvent(e: IEvent): e is IBoardEvent {
		return 'boardId' in e;
	}

	const getLinkPath = () => {
		if (isBoardEvent(event)) return `/boards/${event.boardId}`;
		return '#';
	};

	return (
		<Link className="event-card" to={getLinkPath()}>
			<Card className="pt-2 pb-3" aria-label="Event">
				<Card.Body>
					<Badge pill bg="dark" className="event-type">
						{event.eventType}
					</Badge>
					<Badge pill bg="secondary" className="event-date">
						{dayjs(event.date).format('Do MMM YY')}
					</Badge>
					{
						(!maxLength ||
						event.description.length <= maxLength) &&
						<Card.Text>
							{event.description}
						</Card.Text>
					}
					{
						maxLength &&
						event.description.length > maxLength &&
						<OverlayTrigger
							placement='left'
							overlay={
								<Tooltip>
									{event.description}
								</Tooltip>
							}>
							<Card.Text>
								{event.description.substring(0, maxLength).trimEnd() + '...'}
							</Card.Text>
						</OverlayTrigger>
					}
				</Card.Body>
			</Card>
		</Link>
	);
}

export default EventCard;
