import React, {useEffect, useState} from 'react';
import {without} from 'lodash';
import {IFile} from "../../ModelContracts";
import {Button, Stack} from "react-bootstrap";
import FilePreview from "../FilePreview/FilePreview";
import {useSearchParams} from "react-router-dom";

import './FileList.scss';

interface IProps {
	files: Array<IFile>,
	allowDelete?: boolean,
	onDeleted: {
		(file: IFile): void
	}
}

function FileList({files, onDeleted, allowDelete = true}: IProps) {
	const [listFiles, setListFiles] = useState<Array<IFile>>(files);
	const [searchParams, setSearchParams] = useSearchParams();
	const [previewFile, setPreviewFile] = useState<IFile | null>(null);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	useEffect(() => {
		const selectedFile = listFiles.find(f => f.id === searchParams.get('fileId'));
		setPreviewFile(selectedFile ?? null);
	}, [listFiles, searchParams]);

	useEffect(() => {
		setListFiles(files);
	}, [files]);

	const getFileExtension = (file: IFile) => {
		return file.name.split('.').pop();
	}

	const handleClick = (file: IFile) => {
		if(isDeleting) {
			handleDelete(file);
			return;
		}
		const params = searchParams;
		params.append('fileId', file.id);
		setSearchParams(params);
	}

	const handleClose = () => {
		const params = searchParams;
		params.delete('fileId');
		setSearchParams(params);
	}

	const handleChange = (file: IFile) => {
		const params = searchParams;
		params.set('fileId', file.id);
		setSearchParams(params);
	};

	const handleStartDeleting = () => {
		setIsDeleting(true);
	};

	const handleStopDeleting = () => {
		setIsDeleting(false);
	};

	const handleDelete = (file: IFile) => {
		const updateFiles = without(listFiles, file);
		setListFiles(updateFiles);
		if(!updateFiles.length) setIsDeleting(false);
		onDeleted(file);
	};

	return (
		<>
			<Stack className="file-list justify-content-between align-items-center" direction="horizontal">
				<ul aria-label="File List" className="list-unstyled m-0 d-flex justify-content-start fs-4 gap-2">
					{
						listFiles.map((file: IFile) => (
							<li key={file.id}>
								<Button
									className={`file fs-0 p-0 text-secondary ${isDeleting ? 'deleting' : ''}`}
									title={file.name}
									variant="link"
									onClick={() => handleClick(file)}>
									<i role="presentation"
									   className={`fs-4 bi bi-file-earmark bi-filetype-${getFileExtension(file)}`}></i>
									{file.name}
								</Button>
							</li>
						))
					}
				</ul>
				{
					!isDeleting && allowDelete && Boolean(listFiles.length) &&
					<Button variant="link"
							className="btn-delete d-flex align-items-center text-muted text-decoration-none m-0 p-0"
							title="Delete Files"
							onClick={handleStartDeleting}>
						<i className="bi bi-trash"/>
						<span className="visually-hidden">Delete Files</span>
					</Button>
				}
				{
					isDeleting &&
					<Button variant="secondary m-0" onClick={handleStopDeleting}>Done</Button>
				}
			</Stack>
			{
				previewFile &&
				<FilePreview file={previewFile}
							 files={listFiles}
							 isOpen={Boolean(previewFile)}
							 onClose={handleClose}
							 onChange={handleChange}
							 onDelete={handleDelete}/>
			}
		</>
	);
}

export default FileList;
