import React from 'react';
import {IStaleBoardSummary} from "../../ModelContracts";
import {Alert, Badge, Card, ListGroup, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

interface IProps {
	boards: Array<IStaleBoardSummary>
}

function StaleBoardList({ boards } : IProps) {
	return (
		<Card className="summary-lists" aria-label="Stale Onboardings">
			<Card.Body>
				<Stack direction="vertical" className="align-items-center" gap={2}>
					<h4>Stale Onboardings</h4>
					<ListGroup className="w-100">
						{
							boards.slice(0, 2).map((board: IStaleBoardSummary) => (
								<ListGroup.Item
									key={board.id}
									role="listitem"
									aria-label={board.name}
									className="p-0">
									<Link
										to={`/boards/${board.id}`}
										className="d-flex justify-content-between align-items-center text-body py-2 px-3"
										aria-label={board.name}>
										<p className="m-0">{board.name}</p>
										<Badge
											pill
											bg="danger"
											title={`Completed: ${dayjs(board.staleSince).format('Do MMMM YYYY')}`}>
											{dayjs(board.staleSince).format('Do MMM YY')}
										</Badge>
									</Link>
								</ListGroup.Item>
							))
						}
						{
							!boards.length &&
							<Alert variant="secondary">Nothing has gone stale. Keep up the good work!</Alert>
						}
					</ListGroup>
				</Stack>
			</Card.Body>
		</Card>
	);
}

export default StaleBoardList;
