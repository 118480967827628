import React, {useState} from "react";
import {Container, Row, Col, Button, Stack} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {IUser} from "../../ModelContracts";
import UserCard from "../../components/UserCard/UserCard";
import CreateUser from "../../components/CreateUser/CreateUser";
import {Link} from "react-router-dom";

function Account() {
	const [users, setUsers] = useState([] as IUser[]);
	const [isCreating, setIsCreating] = useState(false);
	const {getUsers, getAvailableUsers} = useApi();

	useQuery('users', () => getUsers(), {
		onSuccess: (users: IUser[]) => {
			setUsers(users);
		}
	});

	const {data: availableUsers} =
		useQuery(['available-users'], () => getAvailableUsers());

	const handleStartCreating = () => setIsCreating(true);
	const handleStopCreating = () => setIsCreating(false);

	return (
		<Container fluid>
			<Row className="mt-4 mb-2 align-items-center justify-content-between">
				<Col xs={2}>
					<h2>Account</h2>
				</Col>
				<Col xs={9} className="d-flex justify-content-end">
					<Stack direction="horizontal" className="align-items-center">
						{
							!availableUsers?.moreAvailable &&
                            <p className="small fst-italic m-0 px-3">
                                <Link to={"/plan"}
                                      className="text-decoration-underline">Upgrade plan</Link> or delete user to add
                                more
                            </p>
						}
						<Button onClick={handleStartCreating}
						        disabled={!availableUsers?.moreAvailable}>Create User</Button>
					</Stack>
				</Col>
			</Row>
			<Row className="mt-4">
				{
					users.map((user: IUser) => (
						<Col key={user.id} xs={6} md={3} className="mb-3">
							<UserCard user={user}/>
						</Col>
					))
				}
			</Row>
			<CreateUser isVisible={isCreating} onClose={handleStopCreating}/>
		</Container>
	);
}

export default Account;
