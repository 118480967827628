import React, {ReactNode, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {QueryClientProvider, QueryClient} from "react-query";
import SiteNav from './components/SiteNav/SiteNav';
import {Container, Row, Col} from "react-bootstrap";
import BoardsNav from "./components/BoardsNav/BoardsNav";
import CreateCustomer from "./components/CreateCustomer/CreateCustomer";
import ImpersonateBar from "./components/ImpersonateBar/ImpersonateBar";
import ErrorToast from "./components/ErrorToast/ErrorToast";
import HashLinkObserver from "react-hash-link";
import {ErrorBoundary} from "react-error-boundary";

import './App.scss';
import useAppUser from "./hooks/useAppUser/useAppUser";
import Error from "./components/Error/Error";
import Loader from "./components/Loader/Loader";

export const queryClient = new QueryClient();

export function Provider({children}: { children: ReactNode }) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
		</QueryClientProvider>
	);
}

function App() {
	const [isCreatingCustomer, setCreatingCustomer] = useState(false);
	const {isAuthenticated, isLoading} = useAuth0();
	const {isSetup} = useAppUser();
	const location = useLocation();

	const handleStartCreatingCustomer = () => {
		setCreatingCustomer(true);
	};

	const handleStopCreatingCustomer = () => {
		setCreatingCustomer(false);
	};

	if (!isAuthenticated && !isLoading) return <Navigate to="/login" state={{from: location}}/>;
	if (!isSetup && !isLoading) return <Navigate to="/setup"/>

	return (
		<Provider>
			<Container fluid className="App">
				<header className="row">
					<SiteNav/>
				</header>
				<Row>
					<Col className="col-boards-nav">
						<BoardsNav onAddCustomer={handleStartCreatingCustomer}/>
					</Col>
					<Col xs={12} md={10} className="pb-4 page-content position-relative">
						<ErrorBoundary fallback={<Error/>}>
							<Outlet/>
						</ErrorBoundary>
						<Loader />
					</Col>
				</Row>
				<CreateCustomer isVisible={isCreatingCustomer} onClose={handleStopCreatingCustomer}/>
				<footer className="row">
					<ImpersonateBar/>
				</footer>
				<ErrorToast/>
				<HashLinkObserver/>
			</Container>
		</Provider>
	);
}

export default App;
