import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Offcanvas, Stack, Card} from 'react-bootstrap';
import {DragDropContext} from "react-beautiful-dnd";
import {useParams} from "react-router-dom";
import useApi from "../../hooks/useApi/useApi";
import {ITaskList, ITask} from "../../ModelContracts";
import CreateTask from "../../components/CreateTask/CreateTask";
import TaskList from "../../components/TaskList/TaskList";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ViewTask from "../../components/ViewTask/ViewTask";
import BoardTaskListChart from "../../components/BoardTaskListChart/BoardTaskListChart";
import BoardProgressChart from "../../components/BoardProgressChart/BoardProgressChart";
import Labels from "../../components/Labels/Labels";
import RecentBoardEvents from "../../components/RecentBoardEvents/RecentBoardEvents";
import BoardChannel from "../../components/BoardChannel/BoardChannel";
import UserList from "../../components/UserList/UserList";
import BoardMembers from "../../components/BoardMembers/BoardMembers";
import {BoardContext, BoardProvider} from "../../contexts/BoardContext";

import './Board.scss';
import '../../styles/SideBar.scss';
import {TaskProvider} from "../../contexts/TaskContext";
import BoardChat from "../../components/BoardChat/BoardChat";

interface IMoveTaskModel {
	boardId: string,
	taskId: string,
	targetListId: string,
	index: number
}

interface IProps {
	showChannel?: boolean,
	showChat?: boolean
}

function Board({showChannel, showChat}: IProps) {
	const {boardId, taskId} = useParams() as { boardId: string, taskId?: string };
	const navigate = useNavigate();
	const {board, isAdmin} = useContext(BoardContext);
	const {labels} = board;
	const [taskLists, setTaskLists] = useState<Array<ITaskList>>(board?.taskLists);
	const [isCreating, setIsCreating] = useState(false);
	const [isAddingMember, setIsAddingMember] = useState(false);

	useEffect(() => {
		setTaskLists(board.taskLists);
	}, [board]);

	const {moveTask, archiveBoard, reactivateBoard, getAvailableOnboardings} = useApi();

	const queryClient = useQueryClient();

	const moveTaskCommand =
		useMutation(({boardId, taskId, targetListId, index}: IMoveTaskModel) =>
			moveTask(boardId, taskId, targetListId, index), {
			onSuccess: async () => {
				await queryClient.invalidateQueries('board');
				await queryClient.invalidateQueries('board-events');
			}
		});

	const archiveBoardCommand = useMutation(() => archiveBoard(boardId), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
			await queryClient.invalidateQueries('available-onboardings');
			await queryClient.invalidateQueries('board-events');
		}
	});

	const reactiveBoardCommand = useMutation(() => reactivateBoard(boardId), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
			await queryClient.invalidateQueries('available-onboardings');
			await queryClient.invalidateQueries('board-events');
		}
	});

	const {data: availableOnboardings} =
		useQuery(['available-onboardings'], () => getAvailableOnboardings());

	const handleStartCreating = () => setIsCreating(true);
	const handleStopCreating = () => setIsCreating(false);
	const handleStopEditing = () => {
		navigate(`/boards/${boardId}`);
	};
	const handleCloseChannel = () => {
		navigate(`/boards/${boardId}`);
	};
	const handleStartAddingMember = () => setIsAddingMember(true);
	const handleStopAddingMember = () => setIsAddingMember(false);
	const handleStopDragging = async (result: any) => {
		const {source, destination, draggableId} = result;
		const sourceList = taskLists.find((tl: ITaskList) => tl.id === source.droppableId) as any;
		const targetList = taskLists.find((tl: ITaskList) => tl.id === destination.droppableId) as any;
		const tasks = taskLists.flatMap((tl: ITaskList) => tl.tasks);
		const task = tasks.find((t: ITask) => t.id === draggableId) as ITask;
		const getTasks = (list: ITaskList) => {
			const tasks = [...list.tasks];
			if (list === sourceList) tasks.splice(source.index, 1);
			if (list === targetList) tasks.splice(destination.index, 0, task);
			return tasks;
		};
		const updatedTaskLists = taskLists.map((tl: any) => {
			return {
				...tl,
				tasks: getTasks(tl)
			}
		}) as any;
		setTaskLists(updatedTaskLists);
		moveTaskCommand.mutate({
			boardId,
			taskId: task.id,
			targetListId: targetList.id,
			index: destination.index
		});
	};

	const hasTasks = () => taskLists.flatMap((tl: ITaskList) => tl.tasks).length > 0;

	const handleArchive = () => {
		archiveBoardCommand.mutate();
	};

	const handleReactivate = () => {
		reactiveBoardCommand.mutate();
	};

	return (
		<DragDropContext onDragEnd={handleStopDragging}>
			<div className="board-container">
				<Container fluid className="p-0">
					<Row className="mt-0 mb-3 p-0">
						<Col className="p-0">
							<BoardTaskListChart taskLists={taskLists}/>
						</Col>
					</Row>
				</Container>
				<Container fluid>
					<Row className="d-flex align-items-start board-header justify-content-between">
						<Col className="d-flex align-items-center my-2" xs={5} aria-label="Board Header">
							<Stack>
								<h3 className="me-4 mb-0">{board.name}</h3>
								<Labels labels={labels} maxCharacterCount={30}/>
							</Stack>
						</Col>
						<Col className="d-flex align-items-center my-2 justify-content-end" xs={3}
						     aria-label="Board Member List">
							{
								board.members &&
                  <UserList users={board.members} avatarSize={32} maxCount={5}/>
							}
						</Col>
						{
							isAdmin &&
                <Col className="d-flex my-2 justify-content-end align-items-center gap-2">
                    <Button variant="secondary" onClick={handleArchive}>Archive</Button>
                    <Button variant="secondary" onClick={handleStartAddingMember}>Add Member</Button>
                    <Button onClick={handleStartCreating}>Create Task</Button>
                </Col>
						}
					</Row>
					<Row className="mt-2">
						{
							taskLists.map((taskList: ITaskList, i: number) => (
								<Col key={taskList.id}
								     xs={12}
								     md={3}
								     className="mb-3 mb-md-0">
									<TaskList
										taskList={taskList}
										emptyText={
											i === 0 && !hasTasks() ?
												"Nothing to show here. Create a task to get started." :
												""
										}
										onAdded={
											i === 0 ? handleStartCreating : undefined
										}
										isAdmin={isAdmin}
									/>
								</Col>
							))
						}
						<Col xs={12} md={3}>
							<RecentBoardEvents boardId={boardId}/>
						</Col>
					</Row>
					<Offcanvas
						show={isCreating}
						onHide={handleStopCreating}
						placement="end"
						className="create-task side-bar"
						aria-label="Create task">
						<Offcanvas.Header closeButton>
							<Offcanvas.Title role="heading">Create Task</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<CreateTask
								onCreate={handleStopCreating}
								boardId={boardId}
							/>
						</Offcanvas.Body>
					</Offcanvas>
					<Offcanvas show={Boolean(taskId)}
					           onHide={handleStopEditing}
					           placement="end"
					           className="edit-board-task side-bar">
						<Offcanvas.Header closeButton className="d-flex flex-row-reverse">
						</Offcanvas.Header>
						<Offcanvas.Body>
							<TaskProvider>
								<ViewTask/>
							</TaskProvider>
						</Offcanvas.Body>
					</Offcanvas>
					<Offcanvas show={showChannel}
					           onHide={handleCloseChannel}
					           placement="end"
					           className="view-board-channel">
						<Offcanvas.Header closeButton>
							<Offcanvas.Title role="heading">Board Events</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<BoardChannel boardId={boardId}/>
						</Offcanvas.Body>
					</Offcanvas>
					<Offcanvas show={showChat}
					           placement="end"
					           className="board-chat-container">
						<Offcanvas.Body>
							<BoardChat boardId={boardId} />
						</Offcanvas.Body>
					</Offcanvas>
					<Offcanvas aria-label="Board Members"
					           placement="end"
					           show={isAddingMember}
					           className="side-bar"
					           onHide={handleStopAddingMember}>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title role="heading">Board Members</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<BoardMembers board={board}/>
						</Offcanvas.Body>
					</Offcanvas>
				</Container>
				<Container fluid className="p-0">
					<Row className="p-0">
						<Col className="p-0">
							<BoardProgressChart tasks={taskLists.flatMap(tl => tl.tasks)}/>
						</Col>
					</Row>
				</Container>
				{
					board.isArchived &&
            <Container fluid className="archived-board">
                <Stack className="justify-content-center align-items-center">
                    <Card border="secondary"
                          className="reactivate-board text-center p-2"
                          role="dialog"
                          aria-label="Reactivate">
                        <Card.Body className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="mb-4">Archived</h2>
													{
														Boolean(availableOnboardings) &&
                              <Button disabled={!availableOnboardings?.moreAvailable}
                                      onClick={handleReactivate}>Reactivate</Button>
													}
													{
														!availableOnboardings?.moreAvailable &&
                              <p className="small fst-italic px-3 mt-3">
                                  <Link to={"/plan"}
                                        className="text-decoration-underline">
                                      Upgrade plan</Link> or archive a different customer to reactivate
                              </p>
													}
                        </Card.Body>
                    </Card>
                </Stack>
            </Container>
				}
			</div>
		</DragDropContext>
	);
}

function BoardWithData({showChannel, showChat}: IProps) {
	return (
		<BoardProvider>
			<Board showChannel={showChannel} showChat={showChat} />
		</BoardProvider>
	);
}

export default BoardWithData;
