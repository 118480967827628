import {IAppUser, IBoard, ITask} from "../../ModelContracts";

function usePermissions(user: IAppUser) {
	let accountId = user.accountId;
	if(user.isImpersonating) accountId = user.impersonateAccountId;

	function isBoardAdmin(board: IBoard) {
		return accountId === board.accountId;
	}

	function isTaskAdmin(task: ITask) {
		return accountId === task.accountId;
	}

	return {
		isBoardAdmin,
		isTaskAdmin
	}
}

export default usePermissions;
