import React from 'react';
import ReactQuill, {ReactQuillProps} from "react-quill";

import './TextEditor.scss';

function TextEditor(props: ReactQuillProps) {
	const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
		e.stopPropagation();
		if(!props.onBlur) return;
		(props as any).onBlur();
	};

	return (
		<div className="text-editor" onBlur={handleBlur}>
			<ReactQuill {...{onBlur: undefined, ...props}} bounds=".text-editor" modules={{
				toolbar: [
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[{'list': 'ordered'}, {'list': 'bullet'}],
					['link', 'image', 'video'],
					['clean']
				]
			}}/>
		</div>
	);
}

export default TextEditor;
