import React, {useState} from 'react';
import {ITemplate} from "../../ModelContracts";
import {Button, Card, Modal} from "react-bootstrap";
import Labels from "../Labels/Labels";
import {useSearchParams} from "react-router-dom";

import './TemplateCard.scss';
import '../../styles/Card.scss';
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";

interface IProps {
	template: ITemplate
}

function TemplateCard({template}: IProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [isRemoving, setIsRemoving] = useState(false);
	const {removeTemplate} = useApi();
	const queryClient = useQueryClient();
	const removeTemplateCommand = useMutation(() => removeTemplate(template.id), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('templates');
		}
	});

	const handleViewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const params = searchParams;
		params.append('id', template.id);
		setSearchParams(params);
	};

	const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const params = searchParams;
		params.append('id', template.id);
		params.append('edit', 'true');
		setSearchParams(params);
	};

	const handleStartRemoveClick = () => {
		setIsRemoving(true);
	};

	const handleRemoveClick = () => {
		removeTemplateCommand.mutate();
		setIsRemoving(false);
	};

	const handleCancelClick = () => {
		setIsRemoving(false);
	};

	return (
		<>
			<Card role="listitem" className="template-card bank-card card m-2" aria-label={template.name}>
				<Card.Body className="d-flex p-0">
					<div className="position-relative flex-grow-1 pt-2 pb-4 px-2">
						<Card.Title><h5>{template.name}</h5></Card.Title>
						<Labels labels={template.labels} maxCharacterCount={15}/>
					</div>
					<div className="card-options d-flex flex-column-reverse p-2">
						<Button variant="link" title="Edit Task" onClick={handleEditClick}>
							<i className="bi bi-pencil"></i>
							Edit
						</Button>
						<Button variant="link" title="View" onClick={handleViewClick}>
							<i className="bi bi-binoculars"></i>
							View
						</Button>
						<Button variant="link" title="Delete" onClick={handleStartRemoveClick}>
							<i className="bi bi-trash"></i>
							Delete
						</Button>
					</div>
				</Card.Body>
			</Card>
			<Modal show={isRemoving} role="dialog" aria-label="Remove Template" onHide={handleCancelClick}>
				<Modal.Header closeButton>
					<Modal.Title role="heading">Remove Template</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to remove the template?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={handleCancelClick}>Cancel</Button>
					<Button variant="primary" onClick={handleRemoveClick}>Remove</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default TemplateCard;
