import React from "react";
import {Button} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";

function LogoutButton()
{
	const {logout} = useAuth0();

	return (
		<Button className="logout-button dropdown-item" variant="link" onClick={() => logout()}>Logout</Button>
	);
}

export default LogoutButton;
