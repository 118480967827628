import React, {FormEvent, useState} from 'react';
import {Button, Form, Offcanvas} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import {ICreateAccountModel} from "../../ModelContracts";

interface IProps {
	isVisible: boolean,
	onClose?: Function
}

function CreateAccount({isVisible, onClose} : IProps) {
	const [name, setName] = useState('');
	const [adminEmail, setAdminEmail] = useState('');
	const [adminFirstName, setAdminFirstName] = useState('');
	const [adminLastName, setAdminLastName] = useState('');

	const {createAccount} = useApi();
	const queryClient = useQueryClient();
	const mutation = useMutation((newAccount: ICreateAccountModel) => createAccount(newAccount), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('accounts');
			handleHide();
		}
	});

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.currentTarget?.value);
	};

	const handleAdminEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdminEmail(e.currentTarget?.value);
	};

	const handleAdminFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdminFirstName(e.currentTarget?.value);
	};

	const handleAdminLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAdminLastName(e.currentTarget?.value);
	};

	const handleHide = () => {
		if(onClose) onClose();
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		mutation.mutate({
			name,
			admin: {
				email: adminEmail,
				firstName: adminFirstName,
				lastName: adminLastName
			},
		});
	};

	return (
		<Offcanvas
			show={isVisible} onHide={handleHide} placement="end" className="side-bar" aria-label="Create account">
			<Offcanvas.Header closeButton>
				<Offcanvas.Title><h5>Create Account</h5></Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Form onSubmit={handleSubmit} aria-label="Create account">
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							required={true}
							placeholder="Account name"
							aria-label="Account name"
							onChange={handleNameChange}
							autoFocus
						/>
					</Form.Group>
					<Form.Group className="my-3">
						<Form.Control
							type="email"
							required={true}
							placeholder="Admin email"
							aria-label="Admin email"
							onChange={handleAdminEmailChange}
						/>
					</Form.Group>
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							required={true}
							placeholder="Admin first name"
							aria-label="Admin first name"
							onChange={handleAdminFirstNameChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Control
							type="text"
							required={true}
							placeholder="Admin last name"
							aria-label="Admin last name"
							onChange={handleAdminLastNameChange}
						/>
					</Form.Group>
					<Button variant="primary" type="submit" className="btn-side-bar">
						Create Account
					</Button>
				</Form>
			</Offcanvas.Body>
		</Offcanvas>
	);
}

export default CreateAccount;
