import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Card, Form, Stack, Button, ListGroup, Badge, Alert} from "react-bootstrap";
import './Setup.scss';
import {IBoardInvitation, ICompleteSignupModel} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useNavigate} from "react-router-dom";
import useAppUser from "../../hooks/useAppUser/useAppUser";

function Setup() {
	const {isSetup} = useAppUser();
	const navigate = useNavigate();
	const {completeSignup, getBoardInvitations} = useApi();
	const [boardInvitations, setBoardInvitations] =
		useState<Array<IBoardInvitation>>([]);
	const [model, setModel] = useState<ICompleteSignupModel>({
		firstName: '',
		lastName: '',
		accountName: '',
		acceptedBoardInvitationIds: []
	});

	useEffect(() => {
		if (isSetup) navigate('/');
	}, [isSetup, navigate]);

	useEffect(() => {
		getBoardInvitations().then((data) => {
			setBoardInvitations(data);
			setModel({
				...model,
				acceptedBoardInvitationIds: data.map((d: IBoardInvitation) => d.id)
			});
		});
	}, []);

	const isDisabled = () =>
		!model.firstName || !model.lastName || !model.accountName;

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setModel({
			...model,
			firstName: e.currentTarget.value
		});
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setModel({
			...model,
			lastName: e.currentTarget.value
		});
	};

	const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setModel({
			...model,
			accountName: e.currentTarget.value
		});
	};

	const handleCompleteSignup = async () => {
		await completeSignup(model);
		window.location.reload();
	};

	const handleInvitationChange = async (
		invitation: IBoardInvitation, e: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = e.currentTarget?.checked;
		const acceptedBoardInvitationIds = isChecked ?
			[...model.acceptedBoardInvitationIds, invitation.id] :
			model.acceptedBoardInvitationIds.filter(id => id !== invitation.id);
		setModel({
			...model,
			acceptedBoardInvitationIds
		});
	};

	const isAccepted = (invitation: IBoardInvitation) => {
		return Boolean(model.acceptedBoardInvitationIds.find(id => id === invitation.id));
	};

	return (
		<Container fluid className="setup-page">
			<Row>
				<Col xs={12}>
					<Card className="setup">
						<Card.Header>
							<Card.Title
								className="p-2 m-0 d-flex flex-row align-items-center justify-content-center">
								<Stack direction="vertical" className="flex-grow-0 justify-content-center setup-title">
									<h1 className="mb-0">Welcome to boarded</h1>
									<p className="mb-0 fw-lighter fst-italic text-end">Let's get you boarded</p>
								</Stack>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Container fluid className="p-2 h-100 d-flex">
								<Row className="flex-grow-1">
									<Col md={6} xs={12} className="col-md-border">
										<h3 className="text-center mb-4">Your Account</h3>
										<Form.Group className="mb-3">
											<Form.Control
												type="text"
												placeholder="First name"
												aria-label="First name"
												onChange={handleFirstNameChange}/>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												type="text"
												placeholder="Last name"
												aria-label="Last name"
												onChange={handleLastNameChange}/>
										</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												type="text"
												placeholder="Account name"
												aria-label="Account name"
												onChange={handleAccountNameChange}/>
										</Form.Group>
									</Col>
									<Col md={6} xs={12}>
										<Stack aria-label="Board Invitations">
											<h3 className="text-center mb-4">Invitations</h3>
											{
												Boolean(boardInvitations.length) &&
												<ListGroup>
													{
														boardInvitations.map(invitation => (
															<ListGroup.Item
																key={invitation.id}
																className="d-flex justify-content-between align-items-center">
																<Form.Group>
																	<Form.Check
																		type="checkbox"
																		id="accept-invitation"
																		checked={isAccepted(invitation)}
																		onChange={
																			(e) =>
																				handleInvitationChange(invitation, e)
																		}
																		label={`${invitation.accountName} (${invitation.boardName} Board)`}/>
																</Form.Group>
																<Badge bg="secondary" pill>Board</Badge>
															</ListGroup.Item>
														))
													}
												</ListGroup>
											}
											{
												!boardInvitations.length &&
												<Alert variant="secondary">
													No invitations. Enjoy your own brand new account instead 🎉
												</Alert>
											}
										</Stack>
									</Col>
								</Row>
							</Container>
						</Card.Body>
						<Card.Footer>
							<Stack direction="horizontal" className="p-2 justify-content-center">
								<Button disabled={isDisabled()} onClick={handleCompleteSignup}>Complete Signup</Button>
							</Stack>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Setup;
