import React from 'react';
import {Badge, Button} from "react-bootstrap";
import {IUser} from "../../ModelContracts";
import Gravatar from "react-gravatar";

import './UserLabel.scss';

export interface IProps {
	user: IUser,
	onRemove?: {
		(id: string): void
	}
}

function UserLabel({user, onRemove}: IProps) {
	const handleClick = () => {
		if(!onRemove) return;
		onRemove(user.id);
	};

	const fullName = `${user.firstName} ${user.lastName}`;
	return (
		<Badge data-testid={`Test ID: ${fullName}`} pill bg="primary" className="user-label label me-1 mb-2">
			<Gravatar email={user.email} protocol="https://" size={16}/>
			<span role="label">{fullName}</span>
			{
				onRemove &&
				<Button className="p-0" aria-label="Remove" onClick={handleClick}>
					<i className="bi bi-x-circle-fill"></i>
				</Button>
			}
		</Badge>
	);
}

export default UserLabel;
