import React from 'react';
import {Col, Container, Offcanvas, Row} from "react-bootstrap";
import RecentEvents from "../../components/RecentEvents/RecentEvents";
import {useNavigate} from "react-router-dom";
import AccountChannel from "../../components/AccountChannel/AccountChannel";
import AccountReport from "../../components/AccountReport/AccountReport";
import {useAuth0} from "@auth0/auth0-react";

interface IProps {
	showChannel?: boolean
}

function Home({showChannel}: IProps) {
	const navigate = useNavigate();
	const { user } = useAuth0();

	const handleCloseChannel = () => {
		navigate('/');
	};

	return (
		<>
			<Container fluid>
				<Row className="pt-3 d-flex justify-content-between">
					<Col xs={12} md={9} className="mb-4 mb-md-0">
						<h2 className="mb-3">Hi {user?.nickname ?? 'There'} 👋</h2>
						<AccountReport />
					</Col>
					<Col xs={12} md={3}>
						<RecentEvents />
					</Col>
				</Row>
			</Container>
			<Offcanvas show={showChannel}
					   onHide={handleCloseChannel}
					   placement="end"
					   className="view-board-channel">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title role="heading">Events</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<AccountChannel />
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

export default Home;
