import React, {useEffect, useState} from "react";
import {Container, Row, Col, Offcanvas, Button} from 'react-bootstrap';
import useApi from "../../hooks/useApi/useApi";
import {ITemplate} from "../../ModelContracts";
import TemplateCard from "../../components/TemplateCard/TemplateCard";
import {useQuery} from "react-query";
import EmptyList from "../../components/EmptyList/EmptyList";
import ViewTemplate from "../../components/ViewTemplate/ViewTemplate";
import {useSearchParams} from "react-router-dom";
import CreateTemplate from "../../components/CreateTemplate/CreateTemplate";

import './Templates.scss';
import '../../styles/SideBar.scss';

function Templates() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [templates, setTemplates] = useState([] as ITemplate[]);
	const [templateId, setTemplateId] = useState(searchParams.get('id'));
	const [isCreating, setIsCreating] = useState<boolean>(false);

	const {getTemplates} = useApi();

	useQuery('templates', getTemplates, {
		onSuccess: (templates: ITemplate[]) => {
			setTemplates(templates);
		}
	});

	useEffect(() => {
		setTemplateId(searchParams.get('id'));
	}, [searchParams]);

	const handleCloseTemplate = () => {
		const params = searchParams;
		params.delete('id');
		params.delete('edit');
		setSearchParams(params);
	};

	const handleStartCreating = () => {
		setIsCreating(true);
	};

	const handleStopCreating = () => {
		setIsCreating(false);
	};

	return (
		<Container fluid>
			<Row className="mt-4 mb-2 d-flex justify-content-between align-items-center">
				<Col>
					<h2>Templates</h2>
				</Col>
				<Col className="d-flex justify-content-end">
					<Button onClick={handleStartCreating}>Create Template</Button>
				</Col>
			</Row>
			<Row className="mt-4">
				{
					templates.map((template : ITemplate) => (
						<Col key={template.id} xs={6} md={3}>
							<TemplateCard template={template} />
						</Col>
					))
				}
				{
					!templates.length && <EmptyList />
				}
			</Row>
			<Offcanvas show={Boolean(templateId)}
					   onHide={handleCloseTemplate}
					   placement="end"
					   className="view-template-panel side-bar">
				<Offcanvas.Header className="d-flex flex-row-reverse" closeButton>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<ViewTemplate id={templateId ?? ''}/>
				</Offcanvas.Body>
			</Offcanvas>
			<Offcanvas show={isCreating}
					   onHide={handleStopCreating}
					   placement="end"
					   className="create-template side-bar"
					   role="dialog"
					   aria-label="Create Template">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title role="heading">Create Template</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<CreateTemplate onCreate={handleStopCreating} />
				</Offcanvas.Body>
			</Offcanvas>
		</Container>
	);
}

export default Templates;
