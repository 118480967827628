import React from 'react';
import {Badge, Button} from 'react-bootstrap';

import './Label.scss';

export interface IProps {
	value: string,
	onRemove?: {
		(label: string) : void
	}
}

function Label({value, onRemove} : IProps) {
	const handleClick = () => {
		if(!onRemove) return;
		onRemove(value);
	};

	return (
		<Badge pill bg="primary" className="label me-1">
			<span role="label">{value}</span>
			{
				onRemove &&
				<Button className="p-0" aria-label="Remove label" onClick={handleClick}>
					<i className="bi bi-x-circle-fill"></i>
				</Button>
			}
		</Badge>
	);
}

export default Label;
