import React from 'react';
import {Alert} from "react-bootstrap";

function EmptyList() {
	return (
		<Alert variant="secondary">No items to display.</Alert>
	);
}

export default EmptyList;
