import React from 'react';
import useAppUser from "../../hooks/useAppUser/useAppUser";

import './ImpersonateBar.scss';
import useApi from "../../hooks/useApi/useApi";
import {useMutation} from "react-query";

function ImpersonateBar() {
	const {isImpersonating} = useAppUser();
	const {unimpersonate} = useApi();

	const unimpersonateMutation = useMutation(() => unimpersonate(), {
		onSuccess: async () => {
			window.location.reload();
		}
	})

	const handleUnimpersonate = async () => unimpersonateMutation.mutate();

	return (
		<>
			{
				isImpersonating &&
				<p className="impersonate-bar" onClick={handleUnimpersonate} title="Unimpersonate">
					<i className="bi bi-incognito" />&nbsp;
					Impersonating Account
				</p>
			}
		</>
	);
}

export default ImpersonateBar;
