import React, {MutableRefObject, useRef} from 'react';
import {OverlayTrigger, Tooltip, Badge} from 'react-bootstrap';
import Label from "../Label/Label";

interface IExtraLabelsProps {
	labels: Array<string>,
	container: MutableRefObject<any>
}

function ExtraLabels({labels, container}: IExtraLabelsProps) {
	const tooltipContent = labels.reduce((acc, curr) => (<>{acc}{curr}<br/></>), <></>);
	return (
		<OverlayTrigger
			placement="right"
			container={container}
			overlay={(props) => <Tooltip {...props}>{tooltipContent}</Tooltip>}>
			<span>
				<Badge pill bg="primary" className="label me-1 mb-2">
					<span>+{labels.length}</span>
				</Badge>
			</span>
		</OverlayTrigger>
	);
}

interface IProps {
	labels: Array<string>,
	maxCharacterCount?: number
}

function Labels({labels, maxCharacterCount}: IProps) {
	const getTotalLength = (labels: Array<string>) => labels.reduce((acc, curr) => acc + curr.length, 0);
	const getDisplayText = (label: string) =>
		maxCharacterCount && label.length > maxCharacterCount ? label.substring(0, maxCharacterCount) + "..." : label;

	const ref = useRef(null);

	const sortedLabels = [...labels].sort((a, b) => a.length - b.length);
	let displayLabels = sortedLabels;
	if (maxCharacterCount && getTotalLength(labels) > maxCharacterCount)
		displayLabels = displayLabels.reduce((acc, curr, i) =>
			i === 0 || getTotalLength([...acc, curr]) <= maxCharacterCount ?
				[...acc, curr] :
				acc, [] as Array<string>
		);
	const extraLabels = sortedLabels.filter(l => !displayLabels.includes(l));
	return (
		<div className="labels" ref={ref}>
			{
				displayLabels.map(l =>
					<OverlayTrigger
						key={l}
						placement="right"
						container={ref}
						overlay={(props) => <Tooltip {...props}>{l}</Tooltip>}>
						<span>
							<Label value={getDisplayText(l)}/>
						</span>
					</OverlayTrigger>
				)
			}
			{
				extraLabels.length > 0 && <ExtraLabels labels={extraLabels} container={ref}/>
			}
		</div>
	);
}

export default Labels;
