import useApi from "../../hooks/useApi/useApi";
import {useEffect, useState} from "react";
import {IChatMessage} from "../../ModelContracts";
import {useMutation, useQueryClient} from "react-query";
import {Card, Col, Container, Row} from "react-bootstrap";
import Markdown from "react-markdown";

import './BoardChat.scss';

interface IProps {
	boardId: string
}

function BoardChat({ boardId } : IProps) {
	const [messages, setMessages] = useState<Array<IChatMessage>>([]);
	const [threadId, setThreadId] = useState<string>();
	const {startBoardChat} = useApi();
	const startChatCommand = useMutation(async () => await startBoardChat(boardId), {
		mutationKey: ['chats'],
		onSuccess: (data) => {
			const updatedMessages = [...messages, ...data];
			setMessages(updatedMessages);
			setThreadId(data[0].threadId);
		}
	});
	const queryClient = useQueryClient();

	useEffect(() => {
		if(queryClient.isMutating({ mutationKey: ['chats'] }) || messages.length) return;
		startChatCommand.mutate()
	}, []);

	return (
		<Container aria-label="Chat Messages">
			{
				messages.map((m, i) => (
					<Row key={`message-${i}`} className="mb-2">
						<Col xs={10}>
							<Card className="bg-info text-white">
								<Card.Body>
									<Card.Text>
										<Markdown>
											{m.message}
										</Markdown>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				))
			}
			{
				Boolean(queryClient.isMutating({ mutationKey: ['chats'] })) &&
				<Row>
            <Col xs={12} className="chat-loader">
                <img src="/loader.svg"
                     alt="Loading"
                     aria-label="Loader" />
            </Col>
        </Row>
			}
		</Container>
	);
}

export default BoardChat;