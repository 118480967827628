import React, {useState} from 'react';
import {useQuery} from "react-query";
import useApi from "../../hooks/useApi/useApi";
import {IEvent, IPagedEventList} from "../../ModelContracts";
import EventCard from "../EventCard/EventCard";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";

import '../../styles/RecentEvents.scss';

interface IProps {
	boardId: string
}

function RecentBoardEvents({boardId}: IProps) {
	const [events, setEvents] = useState<Array<IEvent>>([]);
	const [moreToLoad, setMoreToLoad] = useState<boolean>(false);

	const {getBoardEvents} = useApi();
	useQuery<IPagedEventList>(
		['board-events', boardId],
		() => getBoardEvents(boardId),
		{
			onSuccess: (boardEvents: IPagedEventList) => {
				setEvents(boardEvents.items);
				setMoreToLoad(boardEvents.total > boardEvents.items.length);
			}
		}
	);

	return (
		<div className="recent-events">
			<h4 className="mb-3">Recent Events</h4>
			{
				!events.length &&
				<Alert variant="secondary">No activity on this board yet.</Alert>
			}
			{
				events.map((boardEvent, i) => (
					<div key={`board-event-${i}`} className="mb-2">
						<EventCard event={boardEvent} maxLength={42} />
					</div>
				))
			}
			{
				moreToLoad &&
				<Link to={`/boards/${boardId}/channel`} className="d-block text-center mt-3">
					<i className="bi bi-chevron-right"></i>
					View All
				</Link>
			}
		</div>
	);
}

export default RecentBoardEvents;
