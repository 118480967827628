import React from 'react';
import {Container, Row, Col, Card, Image, Stack} from "react-bootstrap";
import LoginButton from "../../components/LoginButton/LoginButton";

import './Login.scss';

function Login() {
	return (
		<Container fluid className="login-page">
			<Row className="p-5">
				<Col xs={12} className="login rounded-3 py-5">
					<Container fluid>
						<Row className="d-flex align-items-center flex-column-reverse flex-md-row">
							<Col xs={12} md={8} className="info-panel px-5 d-flex flex-column gap-3 pt-5 pt-md-0">
								<h1 className="mb-4">Client onboarding made easy</h1>
								<Stack direction="vertical" className="flex-md-row align-items-center justify-content-between gap-5">
									<Image src="/boarded-communicate.webp" width={150} alt="Dog holding phone" />
									<Stack direction="vertical" className="justify-content-center">
										<h3 className="text-center text-md-start">Communicate</h3>
										<p className="fs-5 text-center text-md-start">Communicate with colleagues and clients in one place to keep your onboarding on
											track.</p>
									</Stack>
								</Stack>
								<Stack direction="vertical" className="flex-md-row flex-md-row-reverse align-items-center justify-content-between gap-5">
									<Image src="/boarded-collaborate.webp" width={150} alt="Dog holding phone" />
									<Stack direction="vertical" className="justify-content-center">
										<h3 className="text-center text-md-start">Collaborate</h3>
										<p className="fs-5 text-center text-md-start">Work together in real time, to reduce your average onboarding time.</p>
									</Stack>
								</Stack>
								<Stack direction="vertical" className="flex-md-row align-items-center justify-content-between gap-5">
									<Image src="/boarded-automate.webp" width={150} alt="Dog holding phone" />
									<Stack direction="vertical" className="justify-content-center">
										<h3 className="text-center text-md-start">Automate</h3>
										<p className="fs-5 text-center text-md-start">No more jumping through hoops, templates replicate what works every time.</p>
									</Stack>
								</Stack>
							</Col>
							<Col xs={12} md={4} className="login-panel pb-5 d-flex flex-column align-items-center justify-content-center">
								<h2>boarded</h2>
								<LoginButton/>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
		</Container>
	)
}

export default Login;
