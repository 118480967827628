import React, {FormEvent, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import TextEditor from "../TextEditor/TextEditor";
import SelectLabels from "../SelectLabels/SelectLabels";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import FileUpload from "../FileUpload/FileUpload";
import PendingFileList from "../PendingFileList/PendingFileList";
import {without} from "lodash";

interface IProps {
	onCreate?: Function,
}

function CreateTemplate({onCreate}: IProps) {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [labels, setLabels] = useState<Array<string>>([]);
	const [files, setFiles] = useState<Array<File>>([]);
	const {createTemplateWithFiles} = useApi();
	const queryClient = useQueryClient();
	const createTemplateCommand = useMutation(
		() => createTemplateWithFiles({name, description, labels}, files),
			{
				onSuccess: async () => {
					await queryClient.invalidateQueries('templates');
					if (onCreate) onCreate();
				}
			}
		);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.currentTarget?.value);
	};

	const handleDescriptionChange = (value: string) => {
		setDescription(value);
	};

	const handleLabelsChange = (labels: Array<string>) => {
		setLabels(labels);
	};

	const handleFilesSelected = (selectedFiles: Array<File>) => {
		setFiles([...files, ...selectedFiles]);
	};

	const handleFileDeleted = (file: File) => {
		const updateFiles = without(files, file);
		setFiles(updateFiles);
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		createTemplateCommand.mutate();
	};

	const isValid = () => Boolean(name) && Boolean(labels.length);

	return (
		<Form onSubmit={handleSubmit} aria-label="Create Template" className="pb-5">
			<Form.Group className="my-3">
				<Form.Control
					type="text"
					placeholder="Template name"
					aria-label="Template name"
					onChange={handleNameChange}
					autoFocus/>
			</Form.Group>
			<Form.Group aria-label="Template Description">
				<TextEditor
					id="template-description"
					placeholder="Template description"
					value={description}
					onChange={handleDescriptionChange}/>
			</Form.Group>
			<FileUpload text="Add media to your template" onSelected={handleFilesSelected} />
			<PendingFileList files={files} onDeleted={handleFileDeleted} />
			<SelectLabels onChange={handleLabelsChange}/>
			<Button variant="primary" type="submit" className="btn-create-template btn-side-bar" disabled={!isValid()}>
				Create Template
			</Button>
		</Form>
	);
}

export default CreateTemplate;
