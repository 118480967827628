import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import {differenceWith} from "lodash";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query";
import {IUser} from "../../ModelContracts";
import UserLabel from "../UserLabel/UserLabel";

import './SelectUsers.scss';

export interface IProps {
	placeholder?: string,
	existingUsers?: Array<IUser>,
	onUserAdded: {
		(user: IUser): void
	},
	onUserRemoved: {
		(user: IUser): void
	}
}

function SelectUsers({placeholder, existingUsers, onUserAdded, onUserRemoved}: IProps) {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isListVisible, setIsListVisible] = useState<boolean>(false);
	const [users, setUsers] = useState<IUser[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>(existingUsers ?? []);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const searchRef = useRef<null | HTMLInputElement>(null);
	const {getUsers} = useApi();
	useQuery(['users', searchTerm], () => getUsers(searchTerm), {
		onSuccess: (users: IUser[]) => {
			setUsers(users);
			setSelectedIndex(Math.min(selectedIndex, users.length - 1));
		}
	});

	useEffect(() => {
		if(!existingUsers) return;
		setSelectedUsers(existingUsers);
	}, [existingUsers])

	const getAvailableUsers = () =>
		differenceWith(users, selectedUsers, (a, b) => a.id === b.id);

	const resetSearch = () => {
		if(!searchRef.current) return;
		searchRef.current.value = '';
		searchRef.current.blur();
	};

	const selectCurrentUser = () => {
		const user = getAvailableUsers()[selectedIndex];
		if(!user) return;
		selectUser(user);
	};

	const selectUser = (user: IUser) => {
		const updatedUsers = [
			...selectedUsers,
			user
		];
		setSelectedUsers(updatedUsers);
		resetSearch();
		setIsListVisible(false);
		setSelectedIndex(-1);
		setSearchTerm('');
		onUserAdded(user);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		setSearchTerm(value);
	};

	const handleFocus = async () => {
		setIsListVisible(true);
	};

	const handleBlur = async () => {
		setIsListVisible(false);
	};

	const handleMouseDown = async (id: string) => {
		const user = users.find(u => u.id === id);
		if(!user) return;
		selectUser(user);
	};

	const handleKeyDown = async (e: React.KeyboardEvent) => {
		e.stopPropagation();
		if(e.code === 'Enter') {
			selectCurrentUser();
			return;
		}
		let modifier = e.code === "ArrowUp" ? -1 : 0;
		modifier = e.code === "ArrowDown" ? 1 : modifier;
		const value = selectedIndex + modifier;
		const index = Math.max(Math.min(value, getAvailableUsers().length - 1), 0);
		setSelectedIndex(index);
	};

	const handleRemoveUser = (id: string) => {
		const userToRemove = selectedUsers.find(u => u.id === id);
		const updatedUsers = selectedUsers.filter(u => u.id !== id);
		setSelectedUsers(updatedUsers);
		onUserRemoved(userToRemove!);
	};

	return (
		<Form.Group className="my-3 select-users">
			<Form.Control
				type="search"
				placeholder={placeholder ?? 'Search users'}
				aria-label="Search users"
				ref={searchRef}
				onChange={handleSearchChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
			/>
			{
				isListVisible &&
				<ul
					className="user-list"
					style={{width: searchRef.current?.clientWidth}}
				>
					{
						getAvailableUsers().map((u: IUser, i: number) => {
							const fullName = `${u.firstName} ${u.lastName}`;
							return (
								<li
									key={u.id}
									aria-label={fullName}
									onMouseDown={() => handleMouseDown(u.id)}
									className={i === selectedIndex ? 'selected': ''}
								>
									{fullName}
								</li>
							)
						})
					}
				</ul>
			}
			<div className="mt-2">
				{
					selectedUsers.map((u: IUser) => (
						<UserLabel key={u.id} user={u} onRemove={handleRemoveUser} />
					))
				}
			</div>
		</Form.Group>
	);
}

export default SelectUsers;
