import React from "react";
import {Button} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation} from "react-router-dom";

function LoginButton() {
	const {loginWithRedirect} = useAuth0();
	const { state } : { state: any } = useLocation();

	const handleClick = async () => {
		await loginWithRedirect({
			appState: {
				returnTo: state?.from
			}
		});
	};

	return (
		<Button className="btn-login" onClick={handleClick}>Get Started</Button>
	);
}

export default LoginButton;
