import React from 'react';
import {useParams} from "react-router-dom";
import ViewTemplate from "../../components/ViewTemplate/ViewTemplate";

import './Template.scss';

function Template() {
	const {templateId} = useParams();
	if(!templateId) return <></>;
	return (
		<div className="template col-12 position-relative">
			<ViewTemplate id={templateId} />
		</div>
	);
}

export default Template;
