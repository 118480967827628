import React from 'react';
import {Button, Stack} from "react-bootstrap";

interface IProps {
	files: Array<File>,
	onDeleted: {
		(file: File): void
	}
}

function PendingFileList({files, onDeleted}: IProps) {
	const getFileExtension = (file: File) => {
		return file.name.split('.').pop();
	}

	const handleClick = (file: File) => {
		onDeleted(file);
	}

	return (
		<Stack className="file-list justify-content-between align-items-center" direction="horizontal">
			<ul aria-label="Pending File List" className="list-unstyled m-0 d-flex justify-content-start fs-4 gap-2">
				{
					files.map((file: File, i: number) => (
						<li key={`${file.name}-${i}`}>
							<Button
								className="file fs-0 p-0 text-secondary deleting"
								title={file.name}
								onClick={() => handleClick(file)}
								variant="link">
								<i role="presentation"
								   className={`fs-4 bi bi-file-earmark bi-filetype-${getFileExtension(file)}`}></i>
								{file.name}
							</Button>
						</li>
					))
				}
			</ul>
		</Stack>
	);
}

export default PendingFileList;
