import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Navbar, Stack,} from "react-bootstrap";
import UserMenu from "../UserMenu/UserMenu";

import './SiteNav.scss';
import PendingInvitations from "../PendingInvitations/PendingInvitations";

function SiteNav() {
	return (
		<Navbar bg="dark" variant="dark" expand="lg" className="col site-nav" aria-label="Site nav" fixed="top">
			<Container fluid className="p-0 d-flex justify-content-between">
				<Link to="/" className="navbar-brand">boarded</Link>
				<Stack direction="horizontal" gap={2}>
					<PendingInvitations />
					<div className="ms-auto">
						<UserMenu />
					</div>
				</Stack>
			</Container>
		</Navbar>
	);
}

export default SiteNav;
