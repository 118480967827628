import React from 'react';
import ViewTask from "../../components/ViewTask/ViewTask";
import {TaskProvider} from "../../contexts/TaskContext";

import './Task.scss';

function Task() {
	return (
		<TaskProvider>
			<div className="task col-12 position-relative">
				<ViewTask />
			</div>
		</TaskProvider>
	);
}

export default Task;
