import React, {useContext, useState} from 'react';
import {IComment} from "../../ModelContracts";
import {Badge, Button, Modal} from "react-bootstrap";
import useFormattedDate from "../../hooks/useFormattedDate/useFormattedDate";
import Gravatar from "react-gravatar";
import TextEditor from "../TextEditor/TextEditor";

import './Comment.scss';
import {TaskContext} from "../../contexts/TaskContext";

interface IProps {
	comment: IComment,
	onChange: { (commentId: string, text: string): void},
	onRemove: { (commentId: string): void }
}

function Comment({comment, onChange, onRemove}: IProps) {
	const [text, setText] = useState(comment.text);
	const {isAdmin} = useContext(TaskContext);
	const [formattedDate] = useFormattedDate(comment.date, 'Do MMM YY HH:mm');
	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const handleStartRemove = () => {
		setShowDialog(true);
	};

	const handleRemove = () => {
		onRemove(comment.id);
	};

	const handleEdit = () => {
		setIsEditing(true);
	};

	const handleStopEdit = () => {
		onChange(comment.id, text);
		setIsEditing(false);
	};

	const handleHideDialog = () => {
		setShowDialog(false);
	};

	const handleChange = (content: string) => {
		setText(content);
	};

	return (
		<>
			<article className="comment" aria-label="Comment">
				<div className="d-flex flex-row-reverse">
					{
						!isEditing &&
						isAdmin &&
						<div className="actions py-0 ps-1 pe-0 d-flex flex-column">
							<Button
								className="remove-comment mb-2 text-muted"
								variant="link"
								onClick={handleStartRemove}
								title="Remove Comment">
								<i className="bi bi-trash fs-6"></i>
								Remove Comment
							</Button>
							<Button className="text-muted" variant="link" onClick={handleEdit} title="Edit Comment">
								<i className="bi bi-pencil"></i>
								Edit Comment
							</Button>
						</div>
					}
					<div className="text pt-2 w-100">
						{
							!isEditing &&
							<p className="comment m-0"
							   dangerouslySetInnerHTML={{__html: comment.text}}></p>
						}
						{
							isEditing &&
							<div>
								<TextEditor
									id="edit-comment"
									placeholder="Edit comment"
									value={text}
									onChange={handleChange}
								/>
								<div className="d-flex justify-content-end mt-2">
									<Button variant="secondary" size="sm" onClick={handleStopEdit}>
										Done
									</Button>
								</div>
							</div>
						}
					</div>
				</div>
				<footer className="d-flex pb-1 pt-2 mt-3 justify-content-between align-items-center">
					<div className="author d-flex align-items-center">
						<Gravatar
							key={comment.author.id}
							email={comment.author.email}
							title={`${comment.author.firstName} ${comment.author.lastName}`}
							protocol="https://"
							role="image"
							size={16}
						/>
						<p className="m-0 ms-1">{`${comment.author.firstName} ${comment.author.lastName}`}</p>
					</div>
					<Badge pill bg="secondary">
						<span>{formattedDate}</span>
					</Badge>
				</footer>
			</article>
			<Modal show={showDialog} role="dialog" aria-label="Remove Comment" onHide={handleHideDialog}>
				<Modal.Header closeButton>
					<Modal.Title role="heading">Remove Comment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to remove the comment?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={handleHideDialog}>Cancel</Button>
					<Button variant="primary" onClick={handleRemove}>Remove</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default Comment;
