import React, {useContext} from 'react';
import {IFile} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import {useSearchParams} from "react-router-dom";
import {Button, Col, Row, ListGroup, Alert, Form, Stack} from "react-bootstrap";
import EditTask from "../EditTask/EditTask";
import TaskStatus from "../TaskStatus/TaskStatus";
import UserList from "../UserList/UserList";
import Labels from "../Labels/Labels";
import Comment from "../Comment/Comment";
import AddComment from "../AddComment/AddComment";
import FileUpload from "../FileUpload/FileUpload";
import FileList from "../FileList/FileList";
import {TaskContext} from "../../contexts/TaskContext";

import './ViewTask.scss';

function ViewTask() {
	const [searchParams, setSearchParams] = useSearchParams();
	const {id, task, isAdmin } = useContext(TaskContext);
	const {addTaskFiles, createTaskComment, editTaskComment, removeTaskComment, removeTaskFile} = useApi();
	const queryClient = useQueryClient();
	const updateOptions = {
		onSuccess: async () => {
			await queryClient.invalidateQueries('board');
			await queryClient.invalidateQueries('task');
			await queryClient.invalidateQueries('board-events');
		}
	};
	const addTaskFilesCommand = useMutation((files: Array<File>) => addTaskFiles(id, files), updateOptions);
	const createTaskCommentCommand = useMutation((text: string) => createTaskComment(id, text), updateOptions);
	const editTaskCommentCommand = useMutation(({ commentId, text } : { commentId: string, text: string }) =>
			editTaskComment(id, commentId, text), updateOptions);
	const removeTaskCommentCommand = useMutation(
		(commentId: string) => removeTaskComment(id, commentId), updateOptions);
	const removeTaskFileCommand = useMutation((fileId: string) => removeTaskFile(id, fileId), updateOptions);

	const handleCommentAdded = (text: string) => {
		createTaskCommentCommand.mutate(text);
	};

	const handleCommentEdited = (commentId: string, text: string) => {
		editTaskCommentCommand.mutate({commentId, text});
	};

	const handleCommentRemoved = (commentId: string) => {
		removeTaskCommentCommand.mutate(commentId);
	};

	const handleFilesSelected = (files: Array<File>) => {
		addTaskFilesCommand.mutate(files);
	};

	const handleFileDeleted = (file: IFile) => {
		removeTaskFileCommand.mutate(file.id);
	};

	const isEditMode = () => {
		const param = searchParams.get('edit');
		if (!param) return false;
		return param.toLowerCase() === 'true' && isAdmin;
	};

	const handleEdit = () => {
		const params = searchParams;
		params.append('edit', 'true');
		setSearchParams(params);
	}

	const handlePreview = () => {
		const params = searchParams;
		params.delete('edit');
		setSearchParams(params);
	}

	if (!task) return <></>;
	return (
		<div className="view-task px-3 pb-5" aria-label="View Task">
			<Row>
				<Col className="d-flex justify-content-between align-items-center view-task-header">
					<h2 className="my-2">{task.name}</h2>
					<TaskStatus task={task}/>
					<UserList users={task.assignees}/>
					{
						!isEditMode() &&
						isAdmin &&
						<Button variant="outline-primary" size="sm" onClick={handleEdit} title="Edit Task">
							<i className="bi bi-pencil me-1"></i>
							Edit
						</Button>
					}
					{
						isEditMode() &&
						<Button variant="outline-primary" size="sm" onClick={handlePreview} title="Preview Task">
							<i className="bi bi-binoculars me-1"></i>
							Preview
						</Button>
					}
				</Col>
			</Row>
			{
				!isEditMode() &&
				<>
					<Row>
						<Col>
							{
								task.description &&
								<p className="description mt-4"
								   dangerouslySetInnerHTML={{__html: task.description}}></p>
							}
							{
								!task.description &&
								<p className="missing-description mt-4">No description set on task.</p>
							}
						</Col>
					</Row>
					<Row className="labels-container col-10 py-2">
						<Col>
							<Labels labels={task.labels}/>
						</Col>
					</Row>
				</>
			}
			{
				isEditMode() && <EditTask task={task}/>
			}
			<Row>
				<Col>
					<Form.Group>
						<FileUpload text="Add media to your task" onSelected={handleFilesSelected} />
						<Stack className="mt-2">
							<FileList files={task.files} onDeleted={handleFileDeleted} allowDelete={isEditMode()} />
						</Stack>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<h3 id="comments" className="my-4">Comments</h3>
					<AddComment onAdded={handleCommentAdded} />
					{
						Boolean(task.comments.length) &&
						<ListGroup as="ul">
							{
								task.comments.map(comment => (
									<ListGroup.Item key={comment.id} as="li">
										<Comment comment={comment}
												 onChange={handleCommentEdited}
												 onRemove={handleCommentRemoved} />
									</ListGroup.Item>
								))
							}
						</ListGroup>
					}
					{
						Boolean(!task.comments.length) &&
						<Alert variant="secondary">No comments yet.</Alert>
					}
				</Col>
			</Row>
		</div>
	);
}

export default ViewTask;
