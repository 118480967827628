import React, {useState} from 'react';
import {ITemplate} from "../../ModelContracts";
import {Form, Row, Col} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import TextEditor from "../TextEditor/TextEditor";
import SelectLabels from "../SelectLabels/SelectLabels";
import FileUpload from "../FileUpload/FileUpload";

interface IProps {
	template: ITemplate
}

function EditTemplate({ template } : IProps) {
	const [name, setName] = useState(template.name);
	const [description, setDescription] = useState(template.description);
	const {addTemplateFiles, renameTemplate, setTemplateDescription, setTemplateLabels} = useApi();
	const queryClient = useQueryClient();

	const updateOptions = {
		onSuccess: async () => {
			await queryClient.invalidateQueries('template');
			await queryClient.invalidateQueries('templates');
		}
	};

	const addTemplateFilesCommand =
		useMutation((files: Array<File>) => addTemplateFiles(template.id, files), updateOptions);
	const renameTemplateCommand =
		useMutation((name: string) => renameTemplate(template.id, name), updateOptions);
	const setTemplateDescriptionCommand =
		useMutation((description: string) => setTemplateDescription(template.id, description), updateOptions);
	const setTemplateLabelsCommand =
		useMutation((labels: Array<string>) => setTemplateLabels(template.id, labels), updateOptions);

	const handleRename = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.value;
		setName(name);
		renameTemplateCommand.mutate(name);
	};

	const handleDescriptionChange = (content: string) => {
		setDescription(content);
	};

	const handleDescriptionChangeComplete = () => {
		setTemplateDescriptionCommand.mutate(description);
	};

	const handleLabelChange = (labels: Array<string>) => {
		setTemplateLabelsCommand.mutate(labels);
	};

	const handleFilesSelected = (files: Array<File>) => {
		addTemplateFilesCommand.mutate(files);
	};

	return (
		<div className="edit-template" aria-label="Edit Template">
			<Row>
				<Col>
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							placeholder="Template name"
							aria-label="Template name"
							value={name}
							onChange={handleRename}
							autoFocus/>
					</Form.Group>
					<Form.Group>
						<TextEditor
							id="template-description"
							data-testid="Template description"
							placeholder="Template description"
							value={description}
							onChange={handleDescriptionChange}
							onBlur={handleDescriptionChangeComplete}
						/>
					</Form.Group>
					<SelectLabels existingLabels={template.labels} onChange={handleLabelChange} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group>
						<FileUpload text="Add media to your template" onSelected={handleFilesSelected} />
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
}

export default EditTemplate;
