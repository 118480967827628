import React, {useCallback, useEffect, useState} from 'react';
import {ToastContainer, Toast} from 'react-bootstrap';
import {useDropzone} from "react-dropzone";

import './FileUpload.scss';

interface IProps {
	text: string,
	onSelected: {
		(files: Array<File>): void
	},
	maxFileSize?: number
}

function FileUpload({text, onSelected, maxFileSize}: IProps) {
	const fileSizeLimit = maxFileSize ?? 10000000;

	const onDrop = useCallback((acceptedFiles: Array<File>) => {
		if (acceptedFiles.length) onSelected(acceptedFiles);
	}, [onSelected]);

	const [isError, setIsError] = useState(false);

	const validator = (file: File) => {
		if (file.size <= fileSizeLimit) return null;
		return {
			code: "file-too-large",
			message: `File is larger than ${fileSizeLimit} bytes`
		}
	};

	const {getRootProps, getInputProps, isFocused, acceptedFiles, fileRejections} = useDropzone({
		onDrop,
		validator,
		accept: {
			'text/csv': ['.csv'],
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
			'image/gif': ['.gif'],
			'image/jpeg': ['.jpeg', '.jpg'],
			'application/json': ['.json'],
			'audio/mpeg': ['.mp3'],
			'video/mp4': ['.mp4'],
			'video/mpeg': ['.mpeg'],
			'image/png': ['.png'],
			'application/pdf': ['.pdf'],
			'application/vnd.ms-powerpoint': ['.ppt'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
			'image/svg+xml': ['.svg'],
			'text/plain': ['.txt'],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
			'application/xml': ['.xml'],
			'application/zip': ['.zip']
		}
	});

	useEffect(() => {
		setIsError(Boolean(fileRejections.length));
	}, [fileRejections]);

	return (
		<div
			className={`file-upload bg-light w-100 mt-3 p-3 text-secondary ${isFocused ? 'focused' : ''}`}
			{...getRootProps({"aria-label": "File Upload"})}>
			<p className="m-0">{text}</p>
			<input {...getInputProps({"aria-label": "File Input"})} />
			<ul className="form-control m-0 mt-2 list-unstyled d-flex justify-content-start flex-wrap position-relative">
				{
					acceptedFiles.map((file: File) => (
						<li key={file.name}>{file.name}</li>
					))
				}
				{
					!Boolean(acceptedFiles.length) &&
					<li>No file chosen</li>
				}
			</ul>
			<ToastContainer position="bottom-start" style={{ zIndex: 1100 }}>
				<Toast show={isError}
					   onClose={() => setIsError(false)}
					   autohide
					   delay={5000}
					   bg="danger">
					<Toast.Header className="d-flex justify-content-between align-items-center">
						<h4 className="fs-6">File(s) Too Large</h4>
					</Toast.Header>
					<Toast.Body className="text-white text-center">
						{fileRejections.length} file{fileRejections.length > 1 ? 's are' : ' is'} too large.
						Maximum size is {fileSizeLimit / 1000000} mb.
					</Toast.Body>
				</Toast>
			</ToastContainer>
		</div>
	);
}

export default FileUpload;
