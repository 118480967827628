import React, {useState} from 'react';
import {Toast, ToastContainer} from "react-bootstrap";
import {useQueryClient} from "react-query";

import './ErrorToast.scss';

function ErrorToast() {
	const queryClient = useQueryClient();
	const [error, setError] = useState('');
	const [isShown, setIsShown] = useState<boolean>(false);
	queryClient.getQueryCache().config.onError = (error: any) => {
		if(error === error.message) return;
		setError(error.message);
		setIsShown(true);
	};

	const handleClose = () => setIsShown(false);

	return (
		<ToastContainer aria-label="Error Toast" position="bottom-start" style={{ zIndex: 1100 }} className="error-toast">
			<Toast show={isShown} onClose={handleClose} bg="danger" autohide delay={5000}>
				<Toast.Header>
					<h4>Error</h4>
				</Toast.Header>
				<Toast.Body>
					{ error }
				</Toast.Body>
			</Toast>
		</ToastContainer>
	);
}

export default ErrorToast;
