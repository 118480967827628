import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SelectUsers from "../SelectUsers/SelectUsers";
import {IBoard, IUser} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import InviteMembers from "../InviteMembers/InviteMembers";

interface IProps {
	board: IBoard
}

function BoardMembers({ board }: IProps) {
	const {addBoardMember, removeBoardMember} = useApi();
	const queryClient = useQueryClient();

	const onSuccess = async () => {
		await queryClient.invalidateQueries('board');
		await queryClient.invalidateQueries('board-events');
	}

	const addMemberMutation =
		useMutation((userId: string) => addBoardMember(board.id, userId), { onSuccess });

	const removeMemberMutation =
		useMutation((userId: string) => removeBoardMember(board.id, userId), { onSuccess });

	const handleAdded = (user: IUser) => {
		addMemberMutation.mutate(user.id);
	};

	const handleRemoved = (user: IUser) => {
		removeMemberMutation.mutate(user.id);
	};

	return (
		<Container data-testid="Add Member Component">
			<Row>
				<Col>
					<SelectUsers existingUsers={board.members} onUserAdded={handleAdded} onUserRemoved={handleRemoved} />
				</Col>
			</Row>
			<Row>
				<Col>
					<InviteMembers board={board} />
				</Col>
			</Row>
		</Container>
	);
}

export default BoardMembers;
