import React from "react";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import Gravatar from "react-gravatar";
import LogoutButton from "../LogoutButton/LogoutButton";
import useAppUser from "../../hooks/useAppUser/useAppUser";
import UserInfo from "../UserInfo/UserInfo";

function UserMenu() {
	const user = useAppUser();
	return (
		<Dropdown>
			<Dropdown.Toggle aria-label="User Menu" variant="link">
				<Gravatar email={user.email} protocol="https://" size={24}/>
			</Dropdown.Toggle>
			<Dropdown.Menu role="menu"
						   aria-label="User Menu"
						   align="end"
						   className="pt-0 shadow-sm"
						   style={{ width: "18rem" }}>
				<Dropdown.Item className="bg-light py-2 rounded-top">
					<UserInfo />
				</Dropdown.Item>
				<Dropdown.Divider className="mt-0"/>
				{
					user.isAdmin &&
					<>
						<Dropdown.Item as={Link} to="admin">Admin</Dropdown.Item>
						<Dropdown.Divider/>
					</>
				}
				<Dropdown.Item as={Link} to="account">Your Account</Dropdown.Item>
				<Dropdown.Item as={Link} to="plan">Your Plan</Dropdown.Item>
				<Dropdown.Item as={Link} to="invitations">Your Invitations</Dropdown.Item>
				<Dropdown.Item as={Link} to="templates">Your Templates</Dropdown.Item>
				<Dropdown.Divider/>
				<Dropdown.Item as={LogoutButton}>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default UserMenu;
