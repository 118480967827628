import React, {FormEvent, useState} from "react";
import {Button, Form, Offcanvas} from "react-bootstrap";
import useApi from "../../hooks/useApi/useApi";
import {useMutation, useQueryClient} from "react-query";
import {ICreateUserModel} from "../../ModelContracts";

interface IProps {
	isVisible: boolean,
	onClose?: Function,
}

function CreateUser({ isVisible = true, onClose } : IProps) {
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const {createUser} = useApi();
	const queryClient = useQueryClient();
	const mutation = useMutation((newUser: ICreateUserModel) => createUser(newUser), {
		onSuccess: async () => {
			await queryClient.invalidateQueries('users');
			await queryClient.invalidateQueries('available-users');
			handleHide();
		}
	})

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget?.value);
	};

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.currentTarget?.value);
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(e.currentTarget?.value);
	};

	const handleSubmit =  async (e: FormEvent) => {
		e.preventDefault();
		mutation.mutate({
			email,
			firstName,
			lastName
		});
	};

	const handleHide = () => {
		if(onClose) onClose();
	};

	return (
		<Offcanvas
			show={isVisible}
			onHide={handleHide}
			placement="end"
			className="side-bar"
			aria-label="Create user">
			<Form onSubmit={handleSubmit} aria-label="Create user">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title><h5>Create User</h5></Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form.Group className="my-3">
						<Form.Control
							type="email"
							placeholder="Email address"
							aria-label="Email address"
							onChange={handleEmailChange}
							autoFocus />
					</Form.Group>
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							placeholder="First name"
							aria-label="First name"
							onChange={handleFirstNameChange} />
					</Form.Group>
					<Form.Group className="my-3">
						<Form.Control
							type="text"
							placeholder="Last name"
							aria-label="Last name"
							onChange={handleLastNameChange} />
					</Form.Group>
					<Button variant="primary" type="submit" className="btn-side-bar">
						Create User
					</Button>
				</Offcanvas.Body>
			</Form>
		</Offcanvas>
	);
}

export default CreateUser;
