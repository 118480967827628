import React, {useContext, useState} from 'react';
import {IFile} from "../../ModelContracts";
import useApi from "../../hooks/useApi/useApi";
import {useQuery} from "react-query"
import {Container, Row, Col, Stack, Button, Toast, ToastContainer} from "react-bootstrap";

import './FilePreview.scss';
import {TaskContext} from "../../contexts/TaskContext";

interface IProps {
	file: IFile,
	files: Array<IFile>,
	onClose: {
		(): void
	},
	onChange: {
		(file: IFile): void
	},
	onDelete: {
		(file: IFile): void
	},
	isOpen?: boolean
}

function FilePreview({file, files, onClose, onChange, onDelete, isOpen = true}: IProps) {
	const {getFile} = useApi();
	const {data, isLoading} = useQuery(file.id, () => getFile(file.path));
	const {isAdmin} = useContext(TaskContext);
	const [copied, setCopied] = useState<boolean>(false);

	const handleCloseClick = () => {
		onClose();
	};

	const handleShareClick = async () => {
		await navigator.clipboard.writeText(window.location.href);
		setCopied(true);
	};

	const handleChangeClick = (direction: number) => {
		const index = files.indexOf(file);
		const targetIndex = (index + direction + files.length) % files.length;
		const targetFile = files[targetIndex];
		onChange(targetFile);
	};

	const handleDeleteClick = () => {
		onDelete(file);
		onClose();
	};

	const isVideo = () => {
		const videoTypes = ['video/mp4', 'video/ogg', 'video/webm'];
		return videoTypes.includes(data.contentType);
	};

	const isAudio = () => {
		const audioTypes = ['audio/mpeg', 'audio/ogg', 'audio/wav'];
		return audioTypes.includes(data.contentType);
	};

	const isImage = () => {
		const imageTypes = [
			'image/png',
			'image/apng',
			'image/avif',
			'image/gif',
			'image/jpeg',
			'image/png',
			'image/svg+xml',
			'image/webp'];
		return imageTypes.includes(data.contentType);
	};

	const isPreviewAvailable = () => {
		return isImage() || isAudio() || isVideo();
	};

	const getFileExtension = () => {
		return data.name.split('.').pop();
	};

	if (!isOpen) return <></>;
	return (
		<Container fluid
		           className="fade modal-backdrop show file-preview opacity-100"
		           aria-label={`Preview ${file.name}`}>
			{!isLoading &&
                <>
                    <Row className="mt-2 flex-column-reverse flex-md-row" data-testid="Preview Header">
                        <Col xs={12} md={6}>
                            <h2 className="text-white fs-4 text-center text-md-start">{data.name}</h2>
                        </Col>
                        <Col xs={12} md={6} className="mb-3 mb-md-0">
                            <Stack direction="horizontal"
                                   className="align-items-center justify-content-center justify-content-md-end me-3"
                                   gap={4}>
								{
									isAdmin &&
                                    <Button variant="link"
                                            className="fs-6 text-white text-decoration-none d-flex align-items-center"
                                            onClick={handleDeleteClick}>
                                        <i className="bi bi-trash text-white me-2"/>
                                        Delete
                                    </Button>
								}
                                <Button variant="link"
                                        className="fs-6 text-white text-decoration-none"
                                        onClick={handleShareClick}>
                                    <i className="bi bi-share text-white me-2"/>
                                    Share
                                </Button>
                                <a
                                    className="fs-6 text-white text-decoration-none btn btn-link"
                                    href={data.path}
                                    title={`Download ${data.name}`}>
                                    <i className="bi bi-download text-white me-2"/>
                                    Download
                                </a>
                                <Button variant="link"
                                        onClick={handleCloseClick}
                                        className="text-white btn-close d-flex align-items-center fs-0">
                                    <i className="bi bi-x fs-2"></i>
                                    Close
                                </Button>
                            </Stack>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={2}>
							{
								files.length > 1 &&
                                <Stack className="h-100 align-items-center justify-content-center">
                                    <Button className="carousel-control-prev h-25 position-static"
                                            variant="link"
                                            onClick={() => handleChangeClick(-1)}>
                                        <span className="carousel-control-prev-icon"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </Button>
                                </Stack>
							}
                        </Col>
                        <Col xs={8}>
                            <Stack
                                aria-label="File Preview"
                                className="preview-container align-items-center justify-content-center flex-grow-0 mt-4">
								{
									isVideo() &&
                                    <video aria-label="Video Preview" controls>
                                        <source src={data.path} type={data.contentType}/>
                                    </video>
								}
								{
									isAudio() &&
                                    <audio aria-label="Audio Preview" controls>
                                        <source src={data.path} type={data.contentType}/>
                                    </audio>
								}
								{
									isImage() &&
                                    <img src={data.path} alt={`Preview ${data.name}`} aria-label="Image Preview"/>
								}
								{
									!isPreviewAvailable() &&
                                    <Stack
                                        aria-label="No Preview"
                                        direction="vertical"
                                        className="no-preview align-items-center justify-content-center">
                                        <a href={data.path} title={`Download ${data.name}`}
                                           className="text-decoration-none d-flex justify-content-center align-items-center flex-column">
                                            <i role="presentation"
                                               className={`text-center text-secondary bi bi-file-earmark bi-filetype-${getFileExtension()}`}/>
                                        </a>
                                    </Stack>
								}
                            </Stack>
                        </Col>
                        <Col xs={2}>
							{
								files.length > 1 &&
                                <Stack className="h-100 align-items-center justify-content-center">
                                    <Button className="carousel-control-next h-25 position-static"
                                            variant="link"
                                            onClick={() => handleChangeClick(1)}>
                                        <span className="carousel-control-next-icon"></span>
                                        <span className="visually-hidden">Next</span>
                                    </Button>
                                </Stack>
							}
                        </Col>
                    </Row>
                </>
			}
			<ToastContainer position="top-center">
				<Toast show={copied}
				       onClose={() => setCopied(false)}
				       autohide
				       delay={3000}
				       bg="success"
				       className="w-100 px-2">
					<Toast.Body className="text-white text-center">Copied!</Toast.Body>
				</Toast>
			</ToastContainer>
		</Container>
	);
}

export default FilePreview;
