import React from 'react'
import {ITaskList} from "../../ModelContracts";

import './BoardTaskListChart.scss';

interface IProps {
	taskLists: Array<ITaskList>
}

function BoardTaskListChart({ taskLists } : IProps) {
	const getWidth = (taskList: ITaskList) => {
		const taskCount = taskLists.flatMap(tl => tl.tasks).length;
		return `${(taskList.tasks.length / taskCount) * 100}%`;
	};

	return (
		<div className="task-list-chart" aria-label="Task List Chart">
			{
				taskLists.map((tl, i) => (
					<div
						key={tl.id}
						className={`task-list-${i + 1}`}
						role="status"
						style={{ width: getWidth(tl) }}
						title={`${tl.name}: ${tl.tasks.length}`}>
					</div>
				))
			}
		</div>
	);
}

export default BoardTaskListChart;
