import React, {useState} from 'react';
import useApi from "../../hooks/useApi/useApi";
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {IFile, ITemplate} from "../../ModelContracts";
import {useMutation, useQuery, useQueryClient} from "react-query";
import Labels from "../Labels/Labels";
import {useSearchParams} from "react-router-dom";
import EditTemplate from "../EditTemplate/EditTemplate";
import FileList from "../FileList/FileList";

import "./ViewTemplate.scss";

interface IProps {
	id: string
}

function ViewTemplate({id}: IProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [template, setTemplate] = useState<ITemplate | null>();
	const {getTemplate, removeTemplateFile} = useApi();
	const queryClient = useQueryClient();
	useQuery<ITemplate>('template', () => getTemplate(id), {
		onSuccess: (data: ITemplate) => {
			setTemplate(data);
		}
	});
	const updateOptions = {
		onSuccess: async () => {
			await queryClient.invalidateQueries('template');
			await queryClient.invalidateQueries('templates');
		}
	};
	const removeTemplateFileCommand =
		useMutation((fileId: string) => removeTemplateFile(id, fileId), updateOptions);

	const handleEdit = () => {
		const params = searchParams;
		params.append('edit', 'true');
		setSearchParams(params);
	}

	const handleFileDeleted = (file: IFile) => {
		removeTemplateFileCommand.mutate(file.id);
	};

	const handlePreview = () => {
		const params = searchParams;
		params.delete('edit');
		setSearchParams(params);
	}

	const isEditMode = () => {
		const param = searchParams.get('edit');
		if (!param) return false;
		return param.toLowerCase() === 'true'
	};

	if (!template) return <></>;
	return (
		<div className="view-template px-3 pb-5" aria-label="View Template">
			<Row>
				<Col className="d-flex justify-content-between align-items-center">
					<h2 className="my-2">{template.name}</h2>
					{
						!isEditMode() &&
						<Button variant="outline-primary" size="sm" onClick={handleEdit} title="Edit Template">
							<i className="bi bi-pencil me-1"></i>
							Edit
						</Button>
					}
					{
						isEditMode() &&
						<Button variant="outline-primary" size="sm" onClick={handlePreview} title="Preview Task">
							<i className="bi bi-binoculars me-1"></i>
							Preview
						</Button>
					}
				</Col>
			</Row>
			{
				!isEditMode() &&
				<>
					<Row>
						<Col>
							{
								template.description &&
								<p className="description mt-4"
								   dangerouslySetInnerHTML={{__html: template.description}}></p>
							}
							{
								!template.description &&
								<p className="missing-description fst-italic mt-4">No description set on template.</p>
							}
						</Col>
					</Row>
					<Row className="labels-container col-10 py-2">
						<Col>
							<Labels labels={template.labels}/>
						</Col>
					</Row>
				</>
			}
			{
				isEditMode() && <EditTemplate template={template} />
			}
			<Row>
				<Col>
					<Form.Group>
						<Stack className="mt-2">
							<FileList files={template.files} onDeleted={handleFileDeleted} allowDelete={isEditMode()} />
						</Stack>
					</Form.Group>
				</Col>
			</Row>
		</div>
	);
}

export default ViewTemplate;
